import { Component, HostListener, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { UserService } from 'src/app/services/core/user.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { NewslettersService } from 'src/app/services/newsletters/newsletters.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

import grapesjs from 'grapesjs';
import customCodePlugin from 'grapesjs-custom-code';
import gjsForms from 'grapesjs-plugin-forms';
import grapesjsTouch from 'grapesjs-touch';
import plugin from 'grapesjs-blocks-basic';
import pluginTooltip from 'grapesjs-tooltip';

import 'grapesjs-preset-newsletter';
import 'grapesjs-plugin-ckeditor';
import 'grapesjs-component-countdown';
import 'grapesjs-plugin-export';
import 'grapesjs-tabs';
import 'grapesjs-parser-postcss';
import 'grapesjs-tui-image-editor';
import 'grapesjs-typed';
import 'grapesjs-style-bg';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.page.html',
  styleUrls: ['./newsletter.page.scss'],
})
export class NewsletterPage implements OnInit {

  aiSettingsOptions: aiSettingsOptions = {
    max_tokens: (32 * 1024),
    model: 'o1-mini',
    operations: ['text-generation'],
    temperature: 1,
  };

  cards: any = {};

  editor: any;

  fallbackImg: string = './assets/img/fallback.webp';

  index: number = 0;

  mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    showGenerateOptions: false,
  };

  newsletter: newsletter = {
    language: 'en',
    title: '',
  };

  newsletters: newsletter[];

  state: state = {};

  user: user;

  view: any = {
    ai: {
      config: {},
      models: [],
      providers: [],
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    iMaxFails: 3,
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    title: 'create_newsletter',
    media: {},
    mediaList: [],
    newsletter: {
      settings: {
        branding: {
          colors: {
            primary: '',
            secondary: '',
          },
        },
      },
      sources: {},
    },
    newsletterType: 'blog',
    newsletterTypes: [
      {
        icon: 'document-text-outline',
        name: 'newsletter_type_blog',
        uid: 'blog',
      },
      {
        icon: 'storefront-outline',
        name: 'newsletter_type_ecommerce',
        uid: 'ecommerce',
      },
      {
        icon: 'desktop-outline',
        name: 'newsletter_type_landingpage',
        uid: 'landingpage',
      },
      {
        icon: 'id-card-outline',
        name: 'newsletter_type_recruiting',
        uid: 'recruiting',
      },
    ],
  };

  constructor(
    private aiTools: AiToolsService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navParams: NavParams,
    private newslettersService: NewslettersService,
    private sidebar: SidebarService,
    private sources: SourcesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.user = this.userService.getUser() || {};

    let mode = this.navParams.get('mode');

    this.view.mode = mode || 'create';

    const newsletterFromParams: newsletter | null = this.navParams.get('newsletter');
    this.calcNewsletterVars(newsletterFromParams);
  }

  addSources() {
    this.sources.pick({
      multiple: true,
    })
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length) {

          let sources: source[] = this.view.sources || [], existingSourcesUids: number[] = sources.map((source: source) => {
            return source.uid;
          });

          this.view.newsletter.sources = this.newsletter.sources || {};

          this.view.sources = (this.view.sources || []).concat(response.data.items.filter((source: source) => {
            this.view.newsletter.sources[source.uid] = this.view.newsletter.sources[source.uid] || source;
            this.view.newsletter.sources[source.uid].checked = true;

            return existingSourcesUids.indexOf(source.uid) === -1;
          }));

        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      })
  }

  aiCreate() {

    if (!this.view.createNewsletterInput || !this.view.createNewsletterInput.length) {
      return false;
    }

    const validate: any = this.tools.validateUrl(this.view.createNewsletterInput);

    this.view.isValidWebsiteUrl = validate.success;

    if (!!this.view.isValidWebsiteUrl) {
      return this.aiCreateFromUrl(this.view.createNewsletterInput);
    }

    this.view.generating = true;
    this.view.loading = true;
    this.view.cssBackup = this.editor.getCss();

    const html: string = (this.editor.getHtml() || '');
    const extract: any = this.extractCSS(html);
    const bodySelect: any = /<body.*?>([\s\S]*)<\/body>/.exec(`${extract.html || html}`);
    const bodyHtml: any = bodySelect[1];

    if (!bodyHtml || !bodyHtml.length) {
      return false;
    }

    this.newslettersService.generate({
      query: this.view.createNewsletterInput,
      post_content: `${bodyHtml}`,
    }, this.view.ai.config)
      .then((response: any) => {
        this.view.generating = false;
        this.view.loading = false;

        if (!!response && !!response.output) {
          let replacement: string = `${extract.html || html}`.replace(bodyHtml, response.output);
        }

        this.applyAiResponse(response);
      })
      .catch((error: any) => {
        this.view.iLastFails = this.view.iLastFails || 0;
        this.view.iLastFails++;
        this.view.generating = false;
        this.view.loading = false;

        if (!!error && (error.indexOf('resource_busy') !== -1) && (this.view.iLastFails < this.view.iMaxFails)) {
          console.warn('resources busy, retrying aiCreate', this.view.iLastFails, this.view.iMaxFails, error);
          return this.aiCreate();
        } else {
          this.events.publish('error', error);
        }
      });
  }

  aiCreateFromUrl(url: string) {

    if (!url || !url.length) {
      return false;
    }

    this.view.loading = true;

    this.aiTools.inspectURL(url, true, {
      index: false,
    })
      .then((response: any) => {
        console.log('inspect url response', response);

        this.view.loading = false;

        // set cta input url if empty
        if (!this.view.ctaInput || !this.view.ctaInput.length) {
          this.view.ctaInput = url;
        }

        let inputPrompt: string = (!!response && !!response.ci && !!response.ci.title ? `${response.ci.title}. ` : '');
        let inputPromptParts: string[] = [];

        if (!!response && !!response.ci) {
          response.ci.logo = (response.ci.logo || (!!response.ci.links && !!response.ci.links['apple-touch-icon'] ? response.ci.links['apple-touch-icon'] : (!!response.ci.links && !!response.ci.links.icon ? response.ci.links.icon : response.ci.icon)) || '');

          this.view.ci = response.ci;

          // apply newsletter title from ci title if set
          if (!!response.ci.title) {
            this.newsletter.title = response.ci.title;
          }

        }

        if (!!response && !!response.ideas) {

          this.view.ideas = (response.ideas || []).map((idea: any) => {

            idea.label = idea.label || idea.text;
            idea.photo = idea.photo || this.fallbackImg;
            idea.name = idea.name || idea.text;
            idea.title = idea.title || idea.text;
            idea.thumbnail = idea.thumbnail || ((idea.url || idea.photo) || null);
            idea.type = idea.type || 'image';
            idea.url = (idea.url || idea.photo) || null;

            return idea;
          });

          // calculate input prompt
          response.ideas.forEach((idea: any) => {

            if (!!idea.headline && (inputPromptParts.indexOf(idea.headline) === -1)) {
              inputPrompt += idea.headline + '. ';
              inputPromptParts.push(idea.headline);
            }

            if (!!idea.text && (inputPromptParts.indexOf(idea.text) === -1)) {
              inputPrompt += idea.text + '. ';
              inputPromptParts.push(idea.text);
            }

          });
        }

        this.startManually();
        this.extractMediaListFromIdeas();

        // re-run ai generation
        this.view.aiPrompt = this.tools.trim(inputPrompt);
        this.runAiPrompt();
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  applyAiResponse(response: any) {

    if (!response || !response.output) {
      return false;
    }

    const split: string[] = `${response.output || ''}`.split('```html'),
      output: string = `${split[split.length - 1] || ''}`.replace('```', ''),
      css: string = (this.editor.getCss() || '');

    let combined: string;

    if ((output.toLowerCase().indexOf('<!doctype') !== -1) || (output.toLowerCase().indexOf('<html>') !== -1)) {
      combined = output;
    } else {
      combined = `<html><head><style>${css}</style></head><body>${output}</body></html>`;
    }

    this.newsletter.html = combined;
    this.newsletter.title = this.newsletter.title || (this.view.createNewsletterInput || '');

    this.view.executed = true;

    this.setHtml(this.newsletter.html);

    if (!!response && !!response.newsletter) {
      this.newsletter = Object.assign(this.newsletter, response.post || {});
    }

    this.loadMissingImages();

    if (!this.newsletter || !this.newsletter.uid) {
      this.create(false);
    }
  }

  attachmentPreviewFailed(key: string) {
    console.warn('attachmentPreviewFailed', key);
  }

  back() {
    this.view.executed = false;
  }

  bindKeyEvents() {
    window.addEventListener('keydown', (event) => {
      event.stopImmediatePropagation();

      this.onKeyDown(event);
    }, false);
  }

  calcNewsletterVars(newsletter: newsletter | null = null) {
    this.newsletter = (newsletter || this.newsletter);

    this.newsletter.settings = this.newsletter.settings || {};
    this.newsletter.settings.branding = this.newsletter.settings.branding || {};

    this.newsletter.settings.branding.colors = this.newsletter.settings.branding.colors || {
      primary: '',
      secondary: '',
    };

    console.log('calculated newsltter', this.newsletter);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.newsletter && !!this.newsletter.uid ? 'edit_newsletter' : 'create_newsletter');

    this.view.canNavigateBack = (!!this.newsletters && (this.index !== 0));
    this.view.canNavigateNext = (!!this.newsletters && (this.index !== this.newsletters.length));
  }

  colorChanged(event: any = null, _target: any = null) {
    _target = event;
  }

  create(blDismiss: boolean = true) {

    if (!!this.newsletter && !!this.newsletter.uid) {
      return this.update();
    }

    let html: string = (this.editor.getHtml() || ''),
      css: string = (this.editor.getCss() || '');

    this.newsletter.html = `<html><style>${css}</style>${html}</html>`;

    let sources: any[] = JSON.parse(JSON.stringify(Object.values(this.view.newsletter.sources))).filter((source: any) => {
      return !!source.checked;
    });

    this.newsletter.sources = sources;

    if (this.newsletter.sources && this.newsletter.sources.length) {
      this.newsletter.sources.forEach((source: any) => {
        if (source.systems) {
          let _systems: string[] = [];
          Object.keys(source.systems).forEach((system: string) => {
            if (_systems.indexOf(system) === -1) {
              _systems.push(system);
            }
          });
          source.systems = _systems;
        }
        delete source.checked;
      });
    }

    this.newslettersService.submit(this.newsletter)
      .then((response: any) => {

        if (!!response && !!response.uid) {
          this.newsletter.uid = response.uid;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        } else {
          this.startManually();
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  extractCSS(html: string) {

    // Remove comments and style tags from HTML
    let cleanedHtml = html.replace(/|]*>|]*>|/gi, '');
    cleanedHtml = cleanedHtml.replace(/<\!--.*?-->/g, "");
    cleanedHtml = cleanedHtml.replace(/<!--([^-]|-[^-]|--+[^->])*--+>/g, '');
    cleanedHtml = cleanedHtml.replace(/style type="text\/css"\n/gi, 'style type="text/css"></style>\n');
    cleanedHtml = cleanedHtml.replace(/link type="text\/css"\n/gi, 'link type="text/css" />\n');

    // Extract CSS from style tags and remove style tags
    let styleTagsRegex = /]*>([^<]+)<\/style>/gm;
    let cssMatches = cleanedHtml.match(styleTagsRegex);
    let css = cssMatches ? cssMatches.map((styleTag) => styleTag.replace(/]*>|<\/style>/g, '')).join('\n') : '';

    // Remove style tags from cleaned HTML
    //cleanedHtml = cleanedHtml.replace(styleTagsRegex, '');
    cleanedHtml = cleanedHtml.replace(/<!--([^-]|-[^-]|--+[^->])*--+>/g, '');
    cleanedHtml = cleanedHtml.replace(/style type="text\/css"\n/gi, 'style type="text/css"></style>\n');
    cleanedHtml = cleanedHtml.replace(/link type="text\/css"\n/gi, 'link type="text/css" />\n');

    // Remove comments from CSS
    css = css.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, '');

    return {
      html: cleanedHtml, // html, //cleanedHtml,
      css: css
    };
  }

  extractMediaListFromIdeas() {

    if (!this.view.ideas || !this.view.ideas.length) {
      return false;
    }

    let mediaList: any[] = [], mediaListUrls: string[] = [];

    this.view.ideas.forEach((idea: any) => {
      if (!!idea.photo && !!idea.photo.length && (mediaListUrls.indexOf(idea.photo) === -1) && (idea.photo.indexOf('fallback') === -1)) {
        mediaList.push(idea);
        mediaListUrls.push(idea.photo);
      }
    });

    // apply media list from ideas
    this.view.mediaList = (mediaList || []);

    // set newsletter photo if not set
    try {
      if ((!this.newsletter.photo || !this.newsletter.photo.length) && !!this.view.mediaList && !!this.view.mediaList[0] && !!this.view.mediaList[0].photo) {
        this.newsletter.photo = this.view.mediaList[0].photo;
      }
    } catch (e) {

    }
  }

  initEditor() {
    var host = 'https://grapesjs.com/';

    // Set up GrapesJS editor with the Newsletter plugin
    this.editor = grapesjs.init({
      selectorManager: { componentFirst: true },
      //clearOnRender: true,
      height: '100%',
      storageManager: {
        type: 'local', // Type of the storage, available: 'local' | 'remote'
        autosave: false, // Store data automatically
        autoload: false, // Autoload stored data on init
        stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        options: {
          local: { // Options for the `local` type
            key: 'gjsProject', // The key for the local storage
          },
        },
      },
      assetManager: {
        assets: [
          host + 'img/grapesjs-logo.png',
          host + 'img/tmp-blocks.jpg',
          host + 'img/tmp-tgl-images.jpg',
          host + 'img/tmp-send-test.jpg',
          host + 'img/tmp-devices.jpg',
        ],
        upload: false,
        //uploadText: 'Uploading is not available in this demo',
      },
      container: '#gjs',
      fromElement: true,
      showOffsets: false,
      plugins: [
        'grapesjs-preset-newsletter',
        //'grapesjs-preset-webpage',

        customCodePlugin,
        grapesjsTouch,
        plugin,
        pluginTooltip,
        gjsForms,

        'grapesjs-plugin-ckeditor',
        'grapesjs-component-countdown',
        'grapesjs-plugin-export',
        'grapesjs-tabs',
        'grapesjs-parser-postcss',
        'grapesjs-tui-image-editor',
        'grapesjs-typed',
        'grapesjs-style-bg',
      ],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          modalLabelImport: 'Paste all your code here below and click import',
          modalLabelExport: 'Copy the code and use it wherever you want',
          codeViewerTheme: 'material',
          importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
          cellStyle: {
            'font-size': '12px',
            'font-weight': 300,
            'vertical-align': 'top',
            color: 'rgb(111, 119, 125)',
            margin: 0,
            padding: 0,
          }
        },
        'grapesjs-plugin-ckeditor': {
          onToolbar: el => {
            el.style.minWidth = '350px';
          },
          options: {
            startupFocus: true,
            extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
            allowedContent: true, // Disable auto-formatting, class removing, etc.
            enterMode: 2, // CKEDITOR.ENTER_BR,
            extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
            toolbar: [
              { name: 'styles', items: ['Font', 'FontSize'] },
              ['Bold', 'Italic', 'Underline', 'Strike'],
              { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'colors', items: ['TextColor', 'BGColor'] },
            ],
          }
        },

        plugin: { flexGrid: true },
        'grapesjs-tui-image-editor': {
          script: [
            // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
            'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
          ],
          style: [
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
          ],
        },
        'grapesjs-tabs': {
          tabsBlock: { category: 'Extra' }
        },
        'grapesjs-typed': {
          block: {
            category: 'Extra',
            content: {
              type: 'typed',
              'type-speed': 40,
              strings: [
                'Text row one',
                'Text row two',
                'Text row three',
              ],
            }
          }
        },
        'grapesjs-preset-webpage': {
          modalImportTitle: 'Import Template',
          modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
          modalImportContent: function (editor) {
            return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
          },
        },
      }
    } as any);

    this.setHtml(this.newsletter.html, true);

    // Let's add in this demo the possibility to test our newsletters
    var pnm = this.editor.Panels;
    var cmdm = this.editor.Commands;
    var md = this.editor.Modal;

    // Add info command
    var infoContainer = document.getElementById("info-panel");

    if (!!cmdm) {
      cmdm.add('open-info', {
        run: (editor: any, sender: any) => {
          var mdlClass = 'gjs-mdl-dialog-sm';
          sender.set('active', 0);
          var mdlDialog = document.querySelector('.gjs-mdl-dialog');
          mdlDialog.className += ' ' + mdlClass;
          infoContainer.style.display = 'block';
          md.open({
            title: 'About this demo',
            content: infoContainer,
          });
          md.getModel().once('change:open', () => {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, '');
          })
        }
      });
    }

    if (!!pnm) {
      // Add info button
      const iconStyle = 'style="display: block; max-width: 22px"';

      pnm.addButton('options', [{
        id: 'view-info',
        label: `<svg ${iconStyle} viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
        </svg>`,
        command: 'open-info',
        attributes: {
          'title': 'About',
          'data-tooltip-pos': 'bottom',
        },
      }]);

      // Beautify tooltips
      [
        ['sw-visibility', 'Show Borders'],
        ['preview', 'Preview'],
        ['fullscreen', 'Fullscreen'],
        ['export-template', 'Export'],
        ['undo', 'Undo'],
        ['redo', 'Redo'],
        ['gjs-open-import-template', 'Import'],
        ['gjs-toggle-images', 'Toggle images'],
        ['canvas-clear', 'Clear canvas']
      ].forEach((item) => {
        try {
          const button: any = pnm.getButton('options', item[0]);

          if (!!button) {
            button.set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom' });
          }
        } catch (e) {
          console.warn('updating editor button failed', e);
        }
      });

    }

    // [
    //   ['open-sm', 'Style Manager'],
    //   ['open-layers', 'Layers'],
    //   ['open-blocks', 'Blocks']
    // ].forEach(function(item) {
    //   pnm.getButton('views', item[0]).set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom', title2: item[1] });
    //   console.log('views', item[0], pnm.getButton('views', item[0]).get('attributes'))
    // });

    var titles = document.querySelectorAll('*[title]');

    for (var i = 0; i < titles.length; i++) {
      var el = titles[i];
      var title = el.getAttribute('title');
      title = title ? title.trim() : '';
      if (!title)
        break;
      el.setAttribute('data-tooltip', title);
      el.setAttribute('title', '');
    }

    // Update canvas-clear command
    cmdm.add('canvas-clear', () => {
      if (confirm('Are you sure to clean the canvas?')) {
        this.editor.runCommand('core:canvas-clear');

        setTimeout(() => {
          localStorage.clear();
        }, 0);
      }
    });

    //this.editor.setDevice('mobilePortrait');

    this.editor.on('load', () => {

      //const deviceManager = this.editor.Devices;
      //const devices = deviceManager.getDevices();

      var $ = grapesjs.$;

      // Hide borders by default
      pnm.getButton('options', 'sw-visibility').set('active', 0);

      // Load and show settings and style manager
      var openTmBtn = pnm.getButton('views', 'open-tm');
      openTmBtn && openTmBtn.set('active', 1);
      var openSm = pnm.getButton('views', 'open-sm');
      openSm && openSm.set('active', 1);

      // Remove trait view
      //pnm.removeButton('views', 'open-tm');

      // Add Settings Sector
      var traitsSector = $('<div class="gjs-sm-sector no-select">' +
        '<div class="gjs-sm-sector-title"><span class="icon-settings fa fa-cog"></span> <span class="gjs-sm-sector-label">Settings</span></div>' +
        '<div class="gjs-sm-properties" style="display: none;"></div></div>');

      var traitsProps = traitsSector.find('.gjs-sm-properties');
      traitsProps.append($('.gjs-trt-traits'));

      $('.gjs-sm-sectors').before(traitsSector);

      traitsSector.find('.gjs-sm-sector-title').on('click', function () {
        var traitStyle = traitsProps.get(0).style;
        var hidden = traitStyle.display == 'none';
        if (hidden) {
          traitStyle.display = 'block';
        } else {
          traitStyle.display = 'none';
        }
      });

      // Open block manager
      var openBlocksBtn = this.editor.Panels.getButton('views', 'open-blocks');
      openBlocksBtn && openBlocksBtn.set('active', 1);
    });

    this.view.loading = false;
  }

  ionViewWillEnter() {
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadMissingImages() {
    try {

      if (!this.newsletter || !this.newsletter.html) {
        return false;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(`${this.newsletter.html || ''}`, 'text/html');
      const images = doc.querySelectorAll('img');

      if (!!images && !!images.length) {
        images.forEach(async (img: any, imgIndex: number) => {
          const altText: string | null = img.getAttribute('alt');
          const imgSearchQuery: string = (!!altText ? altText : (!!this.view.ci && !!this.view.ci.title ? this.view.ci.title : this.newsletter.title));

          const blAltRequiresLogo: boolean = !!altText && (altText.toLowerCase() === 'logo');

          if (!!blAltRequiresLogo && !!this.view.ci && !!this.view.ci.logo) {
            // if logo is requested in alt tag and logo provided, set img src to logo

            img.setAttribute('src', this.view.ci.logo);

            const modifiedHtmlString = doc.documentElement.innerHTML;

            if (!!modifiedHtmlString) {
              this.newsletter.html = modifiedHtmlString;
              this.setHtml(this.newsletter.html);
            }

          } else
            if (!!this.view.mediaList && !!this.view.mediaList && !!this.view.mediaList[imgIndex] && !!this.view.mediaList[imgIndex].photo) {
              // if photo from media list for index is set, use it
              img.setAttribute('src', this.view.mediaList[imgIndex].photo);

              const modifiedHtmlString = doc.documentElement.innerHTML;

              if (!!modifiedHtmlString) {
                this.newsletter.html = modifiedHtmlString;
                this.setHtml(this.newsletter.html);
              }

            } else {
              // else, create new photo

              img.removeAttribute('height');
              img.setAttribute('src', `https://via.placeholder.com/750?text=${imgSearchQuery}`);
              img.setAttribute('width', '100%');

              if (!!imgSearchQuery) {
                this.aiTools.search({
                  blFineTuneInput: true,
                  creative: true,
                  limit: 1,
                  query: `${imgSearchQuery}`,
                  request: 'images',
                  height: 576,
                  width: 1024,
                })
                  .then((response: any) => {

                    if (!!response && !!response.images && !!response.images[0]) {
                      img.setAttribute('src', `${response.images[0]}`);

                      const modifiedHtmlString = doc.documentElement.innerHTML;

                      if (!!modifiedHtmlString) {
                        this.newsletter.html = modifiedHtmlString;
                        this.setHtml(this.newsletter.html);
                      }

                      // set newsletter photo if not set
                      if (!this.newsletter.photo || !this.newsletter.photo.length) {
                        this.newsletter.photo = response.images[0];
                      }

                    }
                  })
                  .catch((error: any) => {
                    console.warn('creating image failed', error);
                  });
              }
            }
        });
      }
    } catch (e) {
      console.warn('loading missing images failed', e);
    }
  }

  loadTemplate(template: any) {
    this.view.template = template;
    this.newsletter.html = `${this.view.template.value || ''}`;

    this.setHtml(this.newsletter.html, true);

    // re-run ai creation
    this.view.createNewsletterInput = `${(this.view.createNewsletterInput || this.newsletter.title) || ''}`;
    this.aiCreate();
  }

  loadTemplates() {
    return new Promise((resolve, reject) => {
      this.newslettersService.getTemplates()
        .then((templates: any[]) => {

          if (!!templates && !!templates.length) {
            templates = this.tools.shuffle(templates);
            /*
            templates = templates
              .sort((a: any, b: any) => {
                
                const _a: string = `${a.title}`.toLowerCase(),
                      _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;
                
                return 0;
              });
              */
          }

          this.view.templates = templates.map((template: any) => {
            template.preview = this.domSanitizer.bypassSecurityTrustHtml(`${template.value || ''}`);
            return template;
          });

          if ((!this.newsletter.html || !this.newsletter.html.length) && !!templates && !!templates[0] && !!templates[0].value) {
            this.view.template = templates[0];
            this.loadTemplate(templates[0]);
          }

          this.initEditor();

          resolve(this.view.templates);
        })
        .catch(reject);
    });
  }

  loadUI() {
    console.log('newsletter: found newsletter', this.newsletter);
    console.log('newsletter: view', this.view);

    this.loadTemplates();

    if (!this.newsletter.html || !this.newsletter.html.length) {
      return false;
    }

    this.view.executed = (!!this.newsletter && !!this.newsletter.uid);

    if (!!this.view.executed) {
      this.startManually();
    }

    this.initEditor();
  }

  navBack() {
    this.index--;
    this.onNavIndexChanged();
  }

  navNext() {
    this.index++;
    this.onNavIndexChanged();
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    this.unbindKeyEvents();
  }

  ngOnInit() {
    const detailItem: any | null = this.newslettersService.detailItem();

    // apply newsletter from details
    this.newsletter = detailItem || (this.newsletter || {});

    this.calcViewVars();
    this.loadCards();
    this.bindKeyEvents();

    this.newsletter = this.newsletter || {};
    this.newsletter.language = this.userService.getLanguage() || 'en';
    this.calcNewsletterVars(this.newsletter);

    this.view.loading = true;

    setTimeout(() => {
      this.loadUI();
    }, 500);
  }

  onAttachmentUrlPasted(key: string) {
    console.log('onAttachmentUrlPasted', key);
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "ArrowLeft":
        if (!this.view.canNavigateBack) { return false; }
        this.navBack();
        break;
      case "ArrowRight":
        if (!this.view.canNavigateNext) { return false; }
        this.navNext();
        break;
    }
  }

  onMediaChanged(event: any = null) {
    console.log('onMediaChanged', event);
  }

  onNavIndexChanged() {
    this.newsletter = this.newsletters[this.index];
    this.view.history = [this.newsletter];

    this.calcViewVars();
    this.loadUI();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  onSourceToggled(sourceId: number) {
    if (this.view.newsletter.sources && this.view.newsletter.sources[sourceId] && this.view.newsletter.sources[sourceId].systems) {
      Object.keys(this.view.newsletter.sources[sourceId].systems).forEach((system: string) => {
        this.view.newsletter.sources[sourceId].systems[system] = this.view.newsletter.sources[sourceId].checked;
      });
    }
  }

  onTemplateCheckboxClicked(template: mediaTemplate, i: number) {
    this.view.template = template;
    this.templateChanged();
  }

  preview() {
    this.view.executed = true;
  }

  runAiPrompt() {
    this.view.loading = true;

    const html: string = `${this.newsletter.html || ''}`;
    const extract: any = this.extractCSS(html);
    const bodySelect: any = /<body.*?>([\s\S]*)<\/body>/.exec(`${extract.html || html}`);
    const bodyHtml: any = bodySelect[1];

    if (!bodyHtml || !bodyHtml.length) {
      return false;
    }

    this.newslettersService.generate({
      query: `${this.view.aiPrompt || ''}`,
      post_content: `${bodyHtml}`,
    }, this.view.ai.config)
      .then((response: any) => {
        this.view.aiPrompt = '';
        this.view.loading = false;

        if (!!response && !!response.output) {
          let replacement: string = `${extract.html || html}`.replace(bodyHtml, response.output);
          console.log('replacement (2)', replacement);
        }

        this.applyAiResponse(response);
      })
      .catch((error: any) => {
        this.view.iLastFails = this.view.iLastFails || 0;
        this.view.iLastFails++;
        this.view.loading = false;

        if (!!error && (error.indexOf('resource_busy') !== -1) && (this.view.iLastFails < this.view.iMaxFails)) {
          console.warn('resources busy, retrying runAiPrompt', this.view.iLastFails, this.view.iMaxFails, error);
          return this.runAiPrompt();
        } else {
          this.events.publish('error', error);
        }
      });
  }

  searchTemplates() {

  }

  setHtml(html: string, blOverwriteCss: boolean = false) {
    html = (html || '').replace('```', '');

    let extract: any = this.extractCSS(html);

    try {

      if (!!blOverwriteCss && !!extract.css) {
        this.view.cssBackup = `${extract.css || ''}`;
      } else
        if (!!this.view.cssBackup) {
          //this.editor.setStyle(`${this.view.cssBackup || ''}`);
        }

      this.editor.setComponents(`${(extract.html || html) || ''}`);

    } catch (e) {
      console.warn('updating editor values failed', e);
    }
  }

  startManually() {
    this.view.startManually = true;

    this.cards.general.open = false;
    this.cards.look_and_feel.open = false;
    //this.cards.templates.open = false;
  }

  templateChanged() {
    if (!!this.view.template && !!this.view.template.value) {
      this.setHtml(this.view.template.value, true);
    }
  }

  thumbnailLoadingFailed(item: any = null) {
    if (!!item) {
      item.photo = this.fallbackImg;
    } else {
      this.view.media.logo_src = this.fallbackImg;
    }
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  unbindKeyEvents() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }

  update() {

    if (!this.newsletter.uid) {
      return this.create(false);
    }

    let html: string = (this.editor.getHtml() || ''),
      css: string = (this.editor.getCss() || '');

    this.newsletter.html = `<html><style>${css}</style>${html}</html>`;

    let sources: any[] = JSON.parse(JSON.stringify(Object.values(this.view.newsletter.sources))).filter((source: any) => {
      return !!source.checked;
    });

    this.newsletter.sources = sources;

    if (this.newsletter.sources && this.newsletter.sources.length) {
      this.newsletter.sources.forEach((source: any) => {
        if (source.systems) {
          let _systems: string[] = [];
          Object.keys(source.systems).forEach((system: string) => {
            if (_systems.indexOf(system) === -1) {
              _systems.push(system);
            }
          });
          source.systems = _systems;
        }
        delete source.checked;
      });
    }

    this.newslettersService.update(this.newsletter)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  uploadAttachment(key: string) {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.newsletter.settings.branding[key] = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useNewsletterType(type: any) {
    this.view.newsletterType = type.uid;
  }

  validateCTAInput() {
    const validate: any = this.tools.validateUrl(this.view.ctaInput);

    this.view.isValidWebsiteUrl = validate.success;

    if (!!this.view.isValidWebsiteUrl) {
      return this.aiCreateFromUrl(this.view.ctaInput);
    }

  }

  viewModeChanged(event: any = null) {
    this.calcViewVars();
  }

}