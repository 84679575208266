import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { TokenService } from 'src/app/services/core/token.service';
import { UserService } from 'src/app/services/core/user.service';

import { AuthResponse } from 'src/app/interfaces/auth/auth_response';
import { AuthUser } from 'src/app/interfaces/auth/auth_user';

import { apiUrl } from 'src/config/variables';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  currentAccessToken: string | null = null;

  private allowedUserGroups: string[] = ['Admin', 'Creator', 'Mitarbeiter', 'Moderator', 'Vertrieb', 'Vorstand'];

  AUTH_SERVER_ADDRESS: string;

  authSubject = new BehaviorSubject(false);

  publicRoutes: string[] = [
    '/abonnements',
    '/ai/assistant/*',
    '/api/explorer',
    '/dani/chat',
    '/login',
    '/register',
    '/forgot-password',
  ];

  constructor(
    private cache: CacheService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private navCtrl: NavController,
    private token: TokenService,
    private userService: UserService,
  ) {
  }

  authorize(user: AuthUser) {
    return new Promise(async (resolve, reject) => {
      try {

        const data: any = {
          access_token: await this.getAccessToken(),
        };

        //console.log('auth data', data);

        if (!data.access_token) {
          this.login(user).subscribe(
            (response: any) => {
              if (response && response.authorisation && response.authorisation.token) {
                data.access_token = response.authorisation.token;
                this.setAccessToken(response.authorisation.token);
                resolve(data);
              } else {
                reject(response);
              }
            },
            (error: any) => { reject(error); }
          );
        } else {
          resolve(data);
        }

      } catch (e) {
        reject(e);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let bl: boolean = this.userService.isLoggedIn();
    const user = this.userService.getUser();

    //console.log('auth: canActivate: route', route);

    if (this.isPublicRoute()) {
      bl = true;
    } else
      if (!user || !user.uid) {
        bl = false;
      } else
        if (!user.classifications || !user.classifications.type) {
          bl = false;
        } else
          if (this.allowedUserGroups.indexOf(user.classifications.type) === -1) {
            bl = false;
          } else {
            bl = true;
          }

    if (!bl) {
      this.navCtrl.navigateRoot('/login');
    }

    //console.log('auth: canActivate: bl', bl);
    return bl;
  }

  clearAccessToken() {
    return this.token.clear();
  }

  clearRefreshToken() {
    return this.token.clear('refresh_token');
  }

  async getAccessToken() {
    return this.token.get('auth_token');
  }

  getAuthorizationBasicToken(user: AuthUser) {
    const token = `${user.email}:${user.password}`;
    return `Basic ${btoa(token)}`;
  }

  getAuthServerAddress(environment: string = 'production') {

    console.log('getAuthServerAddress', environment);

    if (!this.AUTH_SERVER_ADDRESS) {
      this.AUTH_SERVER_ADDRESS = this.configService.getEnvironmentUrl(environment, 'api');
    }

    //console.log('this.AUTH_SERVER_ADDRESS', this.AUTH_SERVER_ADDRESS);
    return this.AUTH_SERVER_ADDRESS;
  }

  getNewAccessToken() {
    const refreshToken = this.getRefreshToken();

    return refreshToken.pipe(
      switchMap((token: any) => {
        if (token && token.value) {

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.value}`
            })
          }

          return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/auth/refresh`, httpOptions);
        } else {
          return of(null);
        }
      })
    );
  }

  getRefreshToken() {
    return from(this.token.get('refresh_token'));
  }

  async init() {
    this.AUTH_SERVER_ADDRESS = apiUrl;
    await this.loadToken();
  }

  isLoggedIn() {
    return this.authSubject.asObservable();
  }

  isPublicRoute(route: string | null = null) {
    return (
      (this.publicRoutes.indexOf(`${route || window.location.pathname}`) !== -1) ||
      (window.location.pathname.indexOf('media/queue/preview') !== -1)
    );
  }

  async loadToken() {
    const token = await this.getAccessToken();

    if (!!token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);

      // @todo: Try auth using credentials first
      const userCredentials: any = this.userService.getApiCredentials();
      //console.log('can user login again using credentials? userCredentials (a)', userCredentials);

      if (this.userService.isLoggedIn()) {
        await this.userService.logout();
        return false;
      }
    }
  }

  login(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/login`, user).pipe(
      tap(async (res: AuthResponse) => {

        if (res.user) {
          this.setAccessToken(res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })
    );
  }

  async logout() {
    this.cache.clear();
    this.authSubject.next(false);
  }

  refresh(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/refresh`, user).pipe(
      tap(async (res: AuthResponse) => {
        if (res.user) {
          this.token.set(res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })
    );
  }

  register(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(`${this.AUTH_SERVER_ADDRESS}/register`, user).pipe(
      tap(async (res: AuthResponse) => {

        if (res.user) {
          this.token.set(res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })

    );
  }

  setAccessToken(token: string) {
    return this.token.set(token);
  }

  validateUser(user: user | null = null, blRedirect: boolean = true) {
    return new Promise((resolve, reject) => {
      let error: string | null = null;
      user = (user || this.userService.getUser());

      if (this.isPublicRoute()) {
        resolve(user);
      } else
        if (!user || !user.uid) {
          error = 'error_missing_user_uid';
        } else
          if (!user.classifications || !user.classifications.type) {
            error = 'error_missing_user_type';
          } else
            if (this.allowedUserGroups.indexOf(user.classifications.type) === -1) {
              error = 'error_user_group_not_allowed';
            } else {
              resolve(user);
            }

      if (!!error) {
        console.warn('> validate error', error);

        if (!!blRedirect) {
          this.userService.logout(user);
          this.navCtrl.navigateRoot('/login', { animated: false });
        }

        reject(error);
      }
    });
  }

}