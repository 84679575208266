import { Injectable } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { StorageService } from "src/app/services/core/storage.service";

import { createAction, props } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  tmp: any = {};

  constructor(
    private events: EventsService,
    private storage: StorageService,
  ) {
    this.initEvents();
  }

  clear() {
    return new Promise((resolve, reject) => {
      this.tmp = {};
      window.localStorage.clear();
      this.storage.clear().then(resolve).catch(reject);
    });
  }

  get(key: string, allowedAge: number = 60, unit: any = 'seconds', blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {

      if(!key) {
        reject('error_missing_cache_key');
        return false;
      }
      
      allowedAge = (allowedAge === -1 ? -1 : (allowedAge || 60)); //(this.network.isConnected() ? (allowedAge || 60) : (60 * 60 * 24 * 7)));
      
      this.getItem(key, blForceRefresh)
      .then((cacheItem: cacheItem) => {
        cacheItem = cacheItem || {};
        
        if(cacheItem.timestamp) {

          var currentTime: any = new Date();
          var cacheTime: any = new Date(cacheItem.timestamp * 1000);
          var diff: any;

          switch(unit) {
              case 'seconds':
                  diff = Math.floor((currentTime - cacheTime) / 1000);
                  break;
              case 'minutes':
                  diff = Math.floor((currentTime - cacheTime) / 1000 / 60);
                  break;
              case 'hours':
                  diff = Math.floor((currentTime - cacheTime) / 1000 / 60 / 60);
                  break;
              case 'days':
                  diff = Math.floor((currentTime - cacheTime) / 1000 / 60 / 60 / 24);
                  break;
              default:
                  diff = currentTime - cacheTime;
          }

          cacheItem.age = diff;
        }

        if(cacheItem && (cacheItem.data !== null) && (allowedAge === -1 || !cacheItem.age || cacheItem.age <= allowedAge)) {
          resolve(cacheItem);
        } else {
          resolve({});
        }
      })
      .catch(reject);
    });
  }

  getItem(key: string, blForceRefresh: boolean = false) {
     return new Promise((resolve, reject) => {
      let tmp = this.getTmp(key);
      if(tmp && !blForceRefresh) {
        resolve(tmp);
      } else {
        this.storage.get(key)
        .then(resolve)
        .catch((e) => {
          console.warn('storage error > item', e);
          resolve(window.localStorage.getItem(key) || tmp);
        });
      }
     });
  }

  getTmp(key: string) {
    return this.tmp[key];
  }

  initEvents() {

    this.events.subscribe("appcms:user:loggedout", () => {
      this.clear();
    });

  }

  remove(key: string) {
    window.localStorage.removeItem(key);
    this.removeTmp(key);
    
    return this.storage.remove(key);
  }

  removeTmp(key: string) {
    delete this.tmp[key];
  }
  
  set(key: string, value: any) {
    return new Promise((resolve, reject) => {

      const options: cacheItem = {
        data: value,
        timestamp: Math.floor(new Date().getTime() / 1000),
      };
      
      try {
        this.setTmp(key, options);
      } catch(e) {
        console.warn('cache: set tmp', e);
      }

      try {
        this.storage.set(key, options).then(resolve).catch(reject);
      } catch(e) {
        console.error('storage error', e);
        try {
          window.localStorage.setItem(key, JSON.stringify(options));
          resolve({});
        } catch(e) {
          console.warn('cache set error', e);
          resolve({});
        }
      }
    });
  }

  setTmp(key: string, options: any) {
    this.tmp[key] = options;
  }

}
