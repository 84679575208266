import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertController, IonContent } from '@ionic/angular';

import { AlertService } from "src/app/services/core/alert.service";
import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { CommentsService } from "src/app/services/social/comments.service";
import { ConfigService } from "src/app/services/core/config.service";
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from "src/app/services/core/events.service";
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { TranslationService } from "src/app/services/core/translation.service";
import { ModalService } from "src/app/services/core/modal.service";
import { UserService } from 'src/app/services/core/user.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { SharingService } from 'src/app/services/sharing/sharing.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ViewService } from 'src/app/services/core/view.service';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { AiToolsPage } from 'src/app/pages/core/ai/ai-tools/ai-tools.page';
import { EditPostTranslationsModalPage } from '../edit-post-translations-modal/edit-post-translations-modal.page';

import { EditorComponent } from 'src/app/components/generic/editor/editor.component';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.page.html',
  styleUrls: ['./edit-post.page.scss'],
})
export class EditPostPage implements OnDestroy, OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_generation',
      'text_to_image',
    ],
  };

  appConfig: pipelineAppConfig;

  captionGeneratorConfig: any = {
    allowCreativesGeneration: true,
  };

  cards: any = {
    caption: { open: false },
    comments: { open: true },
    general: { open: true },
    media: { open: true },
    statistics: { open: true },
  };

  @ViewChild(IonContent) content: IonContent;

  @ViewChildren(EditorComponent) editorList: QueryList<any>;

  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild('headerPopover') headerPopover;
  @ViewChild('historyWrapper', { read: ElementRef }) private historyWrapper: ElementRef;

  index: number = 0;

  isHeaderPopoverOpen: boolean = false;

  integrationsByType: any;

  mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    showGenerateOptions: false,
  };

  post: post;

  postId: number;

  posts: post[];

  state: state = {};

  view: any = {
    ai: {
      config: {},
      models: [],
      providers: [],
    },
    aiPrompt: '',
    content: '',
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    mediaView: {

    },
    platforms: [
      {
        name: 'main',
        uid: 'main',
      }
    ],
    route: '/post/edit',
    states: [
      {
        name: 'draft',
        uid: 'draft',
      },
      {
        name: 'review',
        uid: 'review',
      },
      {
        name: 'post',
        uid: 'post',
      },
    ],
    showMenuButton: true,
    showProjectsSelect: true,
    showReactionsCard: true,
    types: [
      {
        checked: true,
        icon: 'desktop-outline',
        uid: 'blog',
        name: 'post_type_blog',
      },
      {
        checked: false,
        icon: 'share-social-outline',
        uid: 'social',
        name: 'post_type_social',
      },
    ],
    uploading: false,
  };

  constructor(
    private aiBridge: AiBridgeService,
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,

    private alertCtrl: AlertController,
    private alertService: AlertService,
    private clipboard: ClipboardService,
    private commentsService: CommentsService,
    private configService: ConfigService,
    private dani: DaniService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private params: ParamsService,
    private postsService: PostsService,
    private postsAdmin: PostsAdminService,
    private sharing: SharingService,
    private sidebar: SidebarService,
    private sources: SourcesService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private translations: TranslationService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.integrationsByType = this.integrations.getByType();

    this.aiSettings = this.aiWorker.getConfig();

    let post = (this.postsAdmin.detailItem() || {} as any);

    this.view.mode = 'create';

    if (!!post) {
      post.post_content = post.post_content || '';

      this.post = post || {};
      this.view.mode = 'edit';

      this.loadContent();
    }

    this.post = (this.post || {}) as any;
  }

  async addPlatform(platform: string | null = null) {

    // if platform is not defined, show picker
    if (!platform || !platform.length) {

      const choose: any = await this.sharing.pick({
        data: (this.view.availablePlatforms || []),
        multiple: true,
        subLabelKey: null,
      });

      const existingPlatforms: string[] = (this.view.platforms || []).map((platform: any) => {
        return platform.uid;
      });

      let i: number = 0;

      if (!!choose && !!choose.data && !!choose.data.items) {
        choose.data.items.forEach((item: any, index: number) => {
          if (existingPlatforms.indexOf(item.indent) === -1) {
            i++;

            setTimeout(() => {

              this.view.platforms.push({
                icon: item.icon,
                name: item.indent,
                photo: item.photo,
                uid: item.indent,
              });

              this.view.platform = item.indent;
              this.platformChanged(item.indent);
            }, (i * 250));
          }
        });
      }

      return false;
    }

    if (!platform) {
      return false;
    }

    this.view.platforms = this.view.platforms || [
      {
        name: 'main',
        uid: 'main',
      }
    ];

    this.view.platforms.push({
      name: platform,
      uid: platform,
    });
  }

  aiCreate() {

    if (!this.view.createPostInput || !this.view.createPostInput.length) {
      return false;
    }

    this.view.generating = true;
    this.view.loading = true;
    this.view.rebuild = true;

    this.dani.executeCreatePost({
      input: this.view.createPostInput
    })
      .then(async (response: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.rebuild = false;

        if (!!response && !!response.output) {

          if(typeof response.output === 'object' && !!response.output.post_content) {
            this.post = response.output;
          } else {
            this.post.post_content = response.output;
          }

          if (!!response && !!response.post) {
            this.post = Object.assign(this.post, response.post || {});
            this.loadMissingPostPhoto();
          }

          this.loadContent();
          this.startManually();
        }

      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.rebuild = false;

        this.events.publish('error', error);
      });
  }

  alertPublishUid(uid: number) {
    this.translations.get([
      'copy',
      'okay'
    ])
      .subscribe(async (translations: any) => {

        let alertParams = {
          animated: true,
          header: "Beitrag veröffentlicht",
          message: `Der Beitrag wurde mit der ID #${uid} angelegt.`,
          buttons: [
            {
              handler: () => {
                this.clipboard.copyText(`${uid}`);
              },
              text: translations.copy || 'copy',
            },
            {
              text: translations.okay || "Okay",
            },
          ],
        };

        let alert = await this.alertCtrl.create(alertParams);
        alert.present();
      });
  }

  bindKeyEvents() {
    window.addEventListener('keydown', (event) => {
      event.stopImmediatePropagation();

      this.onKeyDown(event);
    }, false);
  }

  calcTypesStates() {
    this.view.selectedTypesList = {};

    this.view.selectedTypes = (this.view.types || []).filter((type: any) => {
      this.view.selectedTypesList[type.uid] = !!type.checked;

      return !!type.checked;
    });

    this.view.hasSelectedTypes = !!this.view.selectedTypes.length;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canPublish = (!!this.post && !this.post.uid);
    this.view.canUpdate = (!!this.post && !!this.post.uid);
    this.view.showMenuButton = !this.view.isModal;
    this.view.title = (!!this.post && !!this.post.uid ? 'edit_post' : 'create_post');

    this.view.canNavigateBack = (!!this.posts && (this.index !== 0));
    this.view.canNavigateNext = (!!this.posts && (this.index !== this.posts.length));
  }

  changeSource() {

    if (!this.userService.isType(['Admin', 'Moderator'])) {
      return false;
    }

    this.sources.pick()
      .then((response: any) => {
        let source: source = (response && response.data && response.data.item ? response.data.item : null);

        if (!!source) {
          this.post.host = source.url;
          this.post.hostLabel = source.name;
          this.post.host_uid = source.uid;
          this.post.language = source.language;

          this.saveDraft();
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  copy(text: string) {

    if (!text) {
      return false;
    }

    this.clipboard.copyText(text);
  }

  create(options: any = {}, blBackground: boolean = false) {
    const post: post = Object.assign(this.post, options);

    this.postsAdmin.submitPost(post)
      .then((response: any) => {

        if (!this.post.uid && !!response && !!response.post && !!response.post.uid) {
          this.post.uid = response.post.uid;
        }

        if (!blBackground) {

          if (!!response && !!response.post && !!response.post.uid) {
            this.alertPublishUid(response.post.uid);
          }

          this.dismiss(response, 'done');
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {

    if (data) {
      data.post = data.post || this.post;
    }

    // if no changes exist, just close
    if (!this.view.blHasChanges) {
      this.modalService.dismiss(data, role);
      return false;
    }

    switch (role) {
      case 'dismiss':
        this.translations.get([
          'discard',
          'save',
          'save_post_changes_title',
          'save_post_changes_text',
        ])
          .subscribe((translations: any) => {
            this.alertService.requestConfirm({
              header: translations.save_post_changes_title || 'save_post_changes_title',
              message: translations.save_post_changes_text || 'save_post_changes_text',
              buttons: [
                {
                  handler: () => {

                    // only save changes if bl is true & post is ready
                    if (!!this.post && !!this.post.post_content) {
                      this.saveDraft();
                    }

                    this.modalService.dismiss(data, role);
                  },
                  role: 'save',
                  text: translations.save || "save",
                },
                {
                  color: 'danger',
                  handler: () => {
                    this.modalService.dismiss(data, 'dismiss');
                  },
                  role: 'discard',
                  text: translations.discard || "discard",
                },
              ],
            })
              .then((bl: boolean) => {

                // only save changes if bl is true & post is ready
                if (!!bl && !!this.post && !!this.post.post_content) {
                  this.saveDraft();
                }

                this.modalService.dismiss(data, role);
              })
              .catch((error: any) => {
                console.warn('alert confirm failed', error);
              });
          });
        break;
      case 'done':
        // close without saving, already saved
        this.modalService.dismiss(data, role);
        break;
      default:

        // save changes if post is ready
        if (!!this.post && !!this.post.post_content) {
          this.saveDraft();
        }

        this.modalService.dismiss(data, role);
        break;
    }

  }

  exportToSavePath(post: post, path: string) {
    this.aiWorker.saveTask({
      input: `${post.name}`,
      output: `${post.post_content}`,
      photo: `${post.photo || post.thumbnail}`,
      save_paths: [path],
      save_params: (this.view.save_params || {}),
    });
  }

  hideSettingsModal() {
    this.view.settingsView = false;
  }

  init() {
    return new Promise((resolve, reject) => {
      if (this.postId) {
        this.loadPost()
          .then(() => {
            console.warn('after post loaded', this.view);
            resolve(this.view);
          })
          .catch(reject);
      } else {
        console.warn('after post loaded', this.view);
        resolve(this.view);
      }
    });
  }

  initPreview() {
    if (this.tools.isDesktop()) {
      this.preview();

      this.view.previewInterval = setInterval(() => {
        this.events.publish('post:preview:update', this.post);
      }, 1000);
    }
  }

  async initShiki() {
    //await loadWasm(import('shiki/onig.wasm'));
  }

  initSortable() {
  }

  ionViewWillEnter() {
    this.view.blHasChanges = false;

    this.calcViewVars();

    this.init()
      .catch((error: any) => {
        console.warn('error', error);
      });
  }

  ionViewWillLeave() {

    if (this.view.previewInterval) {
      clearInterval(this.view.previewInterval);
    }

    this.postsAdmin.detailItem({} as any);
    this.params.set('viewData_createPost', null);
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadComments(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.view.expertMode) {
        reject('error_component_not_visible');
      } else
        if (!this.post || !this.post.uid) {
          reject('error_missing_post_uid');
        } else {
          this.view.comments = [];

          resolve(this.view.comments);
        }
    });
  }

  async loadContent(blLoadMissingImages: boolean = true) {

    if (!this.post || !this.view) {
      return false;
    }

    this.post.post_content = `${this.post.post_content || ''}`.replace('```html', '').replace(/\n\n/g, '</p><p>').replace(/\r\n/g, '<br />').replace('```', '');
    this.view.history = [await this.parsePost(this.post)];

    // if post content is set, skip intro popup
    if (!!this.post.post_content) {
      this.startManually();
    }

    // set content to editor
    try {
      this.editorList.last.setInput(this.post.post_content);
    } catch (e) {
      console.warn('updating editor failed', e);
    }

    // load missing images
    if (!!blLoadMissingImages) {
      this.loadMissingImages();
    }

    setTimeout(() => {
      this.loadExpertSidebarData();
    });
  }

  loadExpertSidebarData() {

    if (!this.view.expertMode) {
      return false;
    }

    // load statistics
    try {
      this.loadStatistics();
    } catch (e) {
      console.warn('loading statistics failed', e);
    }

    // load comments
    try {
      this.loadComments();
    } catch (e) {
      console.warn('loading statistics failed', e);
    }

  }

  loadingTranslatedPostFailed(error: any) {
    this.translations.get([
      'cancel',
      'create_new',
      'loading_translated_post_failed_header',
      'try_again',
    ])
      .subscribe((translations: any) => {

        this.events.publish('alert', {
          header: translations.loading_translated_post_failed_header || 'loading_translated_post_failed_header',
          message: error,
          buttons: [
            {
              text: translations.create_new || 'create_new',
              handler: () => {

              }
            },
            {
              text: translations.try_again || 'try_again',
              handler: () => {
                this.loadLanguage();
              }
            },
            {
              text: translations.cancel || 'cancel',
              role: 'cancel',
            }
          ]
        });
      });
  }

  loadLanguage(language: any | null = null) {

    if (!!language && !!language.indent) {
      this.view.language = language.indent;
    } else {
      this.view.language = language || this.view.language;
    }

    // load temp post object
    if (!!language && !!language.post) {
      this.post = JSON.parse(JSON.stringify(language.post));
      this.postId = (!!this.post && !!this.post.uid ? this.post.uid : null);
    }

    // then load server-side object
    this.loadPost()
      .catch((error: any) => {
        this.loadingTranslatedPostFailed(error);
      });

  }

  async loadMissingImages() {
    try {

      if (!this.post || !this.post.post_content) {
        return false;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(`${this.post.post_content || ''}`, 'text/html');
      const images = doc.querySelectorAll('img');

      if (!!images && !!images.length) {
        images.forEach(async (img) => {
          const altText: string | null = img.getAttribute('alt');

          img.setAttribute('src', `https://via.placeholder.com/750?text=${altText}`);
          this.loadContent(false);

          if (!!altText) {
            this.aiTools.search({
              blFineTuneInput: true,
              creative: true,
              limit: 1,
              query: `${altText}`,
              request: 'images',
              height: 576,
              width: 1024,
            })
              .then((response: any) => {
                if (!!response && !!response.images && !!response.images[0]) {
                  img.setAttribute('src', `${response.images[0]}`);

                  const modifiedHtmlString = doc.documentElement.innerHTML;

                  if (!!modifiedHtmlString) {
                    this.post.post_content = modifiedHtmlString;
                    this.loadContent(false);
                  }
                }
              })
              .catch((error: any) => {
                console.warn('creating image failed', error);
              });
          }
        });
      }
    } catch (e) {
      console.warn('loading missing images failed', e);
    }
  }

  loadMissingPostPhoto() {

    if (!this.post.name || !!this.post.thumbnail || !!this.post.photo) {
      return false;
    }

    this.aiTools.search({
      blFineTuneInput: true,
      creative: true,
      limit: 1,
      query: `${this.post.name}`,
      request: 'images',
      height: 576,
      width: 1024,
    })
      .then((response: any) => {
        if (!!response && !!response.images && !!response.images[0]) {
          this.post.photo = response.images[0];
          this.post.thumbnail = response.images[0];
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async loadPlatforms(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {

          if (!!integrations && !!integrations.length && !!this.integrationsByType.social) {
            integrations = integrations.filter((integration: integration) => {
              integration.indent = integration.indent || `${integration.name}`.replace('integration_', '');
              return this.integrationsByType.social.indexOf(integration.indent) !== -1;
            });
          }

          this.view.availablePlatforms = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  loadPost() {
    return new Promise((resolve, reject) => {
      this.postsService.getPostByUid(this.postId, {
        language: this.view.language,
      }, true)
        .then((post: post) => {

          if (post && post.uid) {
            this.post = post;
            this.loadContent();
          }

          resolve(this.post);
        })
        .catch(reject);
    });
  }

  loadStatistics(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.view.expertMode) {
        reject('error_component_not_visible');
      } else
        if (!this.post || !this.post.uid) {
          reject('error_missing_post_uid');
        } else {
          this.view.chart = {};
          this.view.statistics = [];

          try {
            const postStatistics: any = await this.postsAdmin.getStatisticsByPostUid(this.post.uid, blForceRefresh);
            this.view.statistics = {};

            if (!!postStatistics && !!postStatistics.data) {
              this.view.statistics = Object.assign(this.view.statistics, postStatistics.data);
            }

            resolve(this.view.statistics);
          } catch (e) {
            reject(e);
          }
        }
    });
  }

  loadUIParams() {

    // apply post params if set

    const createPostParams: any = this.params.get('viewData_createPost');

    if (!createPostParams) {
      return false;
    }

    if (!!createPostParams.post) {
      this.post = Object.assign(this.post, createPostParams.post);
    }

    if (!!createPostParams.view) {
      this.view = Object.assign(this.view, createPostParams.view);
    }

    // calc current types states

    this.view.types = [
      {
        checked: (!!this.post && (this.post.type !== 'social')),
        icon: 'desktop-outline',
        uid: 'blog',
        name: 'post_type_blog',
      },
      {
        checked: (!!this.post && (this.post.type === 'social')),
        icon: 'share-social-outline',
        uid: 'social',
        name: 'post_type_social',
      },
    ];

    this.calcTypesStates();
  }

  modelChanged(event: any = null) {
    this.aiSettings = this.aiSettings || {};
    this.aiSettings.model = event;

    this.aiWorker.setConfig(this.aiSettings);
  }

  navBack() {
    this.index--;
    this.onNavIndexChanged();
  }

  navNext() {
    this.index++;
    this.onNavIndexChanged();
  }

  ngAfterContentInit() {
    this.view.history = [this.post];
  }

  ngOnDestroy() {
    this.postsAdmin.detailItem({} as any);
    this.unbindKeyEvents();
  }

  ngOnInit() {
    this.view.blHasChanges = false;

    this.bindKeyEvents();
    this.calcViewVars();
    this.loadCards();

    this.loadUIParams();
    this.loadContent();
    this.loadPlatforms();
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onChannelsChanged(channels: any[] | null = null) {
    this.captionGeneratorConfig.connections = (channels || []);
  }

  onCommentsChanged(comments: any[] | null = []) {
    this.view.comments = (comments || []);
  }

  onEditorInputChanged(value: string, item: any, index: number) {
    item.post_content = `${value || ''}`;

    this.view.blHasChanges = true;

    if (index === 0) {
      this.post.post_content = `${item.post_content || ''}`;
    }

    this.calcViewVars();
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "ArrowLeft":
        if (!this.view.canNavigateBack) { return false; }
        this.navBack();
        break;
      case "ArrowRight":
        if (!this.view.canNavigateNext) { return false; }
        this.navNext();
        break;
    }
  }

  onMediaChanged(event: any = null) {

    if (!!event && !!event.length) {
      event.forEach(async (mediaItem: any) => {

        let mediaType: string = (mediaItem.post_mime_type || mediaItem.type);

        switch (mediaType) {
          case 'image':
            this.post.post_content += `<img src="${mediaItem.guid || (mediaItem.photo || mediaItem.thumbnail)}" />`;

            try {
              this.editorList.last.setInput(this.post.post_content);
            } catch (e) {
              console.warn('updating editor failed', e);
            }

            break;
        }

      });
    }

    this.calcViewVars();
  }

  onNavIndexChanged() {
    this.post = this.posts[this.index];
    this.view.history = [this.post];
    this.view.rebuild = true;

    this.calcViewVars();
    this.loadUIParams();
    this.loadContent();

    setTimeout(() => {
      this.view.rebuild = false;
    }, 100);
  }

  onPostSettingsChanged(event: any = null) {
    console.log('onPostSettingsChanged', event);
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  onVoiceoverSettingsChanged(settings: any) {
    console.log('onVoiceoverSettingsChanged', settings);
  }

  async parsePost(post: any) {
    const contentLines: string[] = `${post.post_content || ''}`.split("\n");

    // detect code in output and beautify it
    if (!!contentLines[0] && (['html', 'javascript', 'php', 'python', 'css', 'java'].indexOf(contentLines[0]) !== -1)) {

      /*
      const highlighter = await getHighlighter({
        themes: [nord], // 'nord',
        langs: ['css', 'javascript', 'python'],
      } as any);

      console.log('highlighter', highlighter);
      */

      /*
      const html: string = await codeToHtml(`${post.post_content || ''}`.replace(`${contentLines[0]}\n`, '').replace('```', ''), {
        lang: contentLines[0],
        theme: 'nord'
      } as any);

      post.post_content_formatted = this.domSanitizer.bypassSecurityTrustHtml(`${html || ''}`);
      console.log('post.post_content_formatted', post.post_content_formatted);
      */
    }

    return post;
  }

  pickTaskMediaTemplate(task: any, type: string) {
    return new Promise((resolve, reject) => {
      this.templates.pick({
        filter: {
          active: true,
          public: true,
          type: type,
        },
        multiple: true,
        project_uid: 0,
      })
        .then((chooseResponse: chooseResponse) => {
          let templates: mediaTemplate[] = (chooseResponse && chooseResponse.data && chooseResponse.data.items ? chooseResponse && chooseResponse.data && chooseResponse.data.items : (chooseResponse && chooseResponse.data && chooseResponse.data.item ? chooseResponse.data.item : []));

          this.view.save_params = this.view.save_params || {};
          this.view.save_params.templates = this.view.save_params.templates || {};
          this.view.save_params.templates[type] = (templates || []);

          resolve(task);
        })
        .catch((error: any) => {
          delete task.save_paths;
          reject(error);
        });
    })
  }

  platformChanged(platform: string | null = null) {
    platform = platform || this.view.platform;

    if (!platform || (platform === 'add')) {
      return false;
    }

    this.view.loading = true;
    this.view.rebuild = true;
    this.view.variationsByPlatform = this.view.variationsByPlatform || {};

    if ((platform === 'main') || !!this.view.variationsByPlatform[platform]) {

      setTimeout(() => {
        this.view.loading = false;
        this.view.rebuild = false;
      }, 250);

      return false;
    }

    const aiParams: any = {
      context: 'blog_post_creation',
      history: [
        {
          input: `You are a creative social media manager. You always reply with the perfect ${platform || 'social media'} caption based on the given customer post`,
          role: 'system',
        },
        {
          input: `IMPORTANT:
          - Follow the best practice standards when creating the article.
          - It should look appealing (e.g. contain emojis, paragraphs, lists, hashtags, ...) and have the potential to go viral.
          - Wrap every part / paragraph of the post in its own <p></p> wrapper tag
          - Format your post (line breaks, paragraphs, links) using HTML (<br />, <p></p>, <a></a>, ...)
          `,
        }
      ],
      post_content: this.tools.stripHtml(`${this.view.aiPrompt || this.post.post_content}`),
    };

    this.aiBridge.execute(aiParams, true, this.aiSettings)
      .then((response: any) => {
        this.view.variationsByPlatform[platform] = this.tools.nl2br(`${response.output || ''}`);
        this.view.loading = false;
        this.view.rebuild = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async preview() {
    this.events.publish('view:post', this.post);
  }

  async rebuild(options: any = {}) {

    if (!this.post || (!this.post.post_content) && !this.post.name) {
      return false;
    }

    this.view.createPostInput = `${(this.post.post_content || this.post.name) || ''}`;

    if (!!this.view.createPostInput) {
      await this.aiCreate();
    }
  }

  rebuildImages() {
    return this.rebuild({ text: false, images: true });
  }

  rebuildTexts() {
    return this.rebuild({ text: true, images: false });
  }

  runAiPrompt() {
    this.view.loading = true;

    setTimeout(() => {
      this.scrollDown();
    }, 100);

    let history: aiExecutionHistoryItem[] = [];

    // calculate history
    if (!!this.view.history && !!this.view.history.length) {

      history = this.view.history.map((historyItem: any) => {
        let input: string = `${(!!historyItem.post_content ? historyItem.post_content : historyItem.input) || ''}`;

        return {
          input: input,
          post_content: input,
          role: (historyItem.role || 'user'),
        };
      });

    } else
      if (!!this.post.post_content) {
        history.push({
          input: `${this.post.post_content || ''}`,
          post_content: `${this.post.post_content || ''}`,
          mode: 'view',
          role: 'user',
        });
      }

    // remove empty items from history
    history = history.filter((item: aiExecutionHistoryItem) => {
      return (!!item.input || !!item.post_content);
    });

    history = [
      {
        input: 'You are a helpful assistant who helps to create blog posts. Detect the language based on the input and respond in that language. Automatically generate a blog post based on the last input. You only reply with the edited blog post (formatted as HTML) starting with the headline.',
        role: 'system',
      } as aiExecutionHistoryItem
    ].concat(history as aiExecutionHistoryItem[]);
    
    let aiParams: any = {
      context: 'blog_post_creation',
      history: history,
      post_content: `${this.view.aiPrompt || this.post.post_content}`,
    };

    this.aiBridge.execute(aiParams, true, this.aiSettings)
      .then((response: any) => {
        this.view.loading = false;

        // add ai input to history if set
        if (!!this.view.aiPrompt) {
          history.push({
            input: `${this.view.aiPrompt || ''}`,
            post_content: `${this.view.aiPrompt || ''}`,
            mode: 'view',
            role: 'user',
          });
        }

        if (!!response && !!response.output) {
          let split: string[] = this.tools.splitTripleBackticksWithCodeBlocks(`${response.output}`);

          this.view.history = history.map((historyItem: any) => {
            historyItem.post_content = (!!historyItem.post_content ? historyItem.post_content : historyItem.input) || '';
            return historyItem;
          });

          if (split.length > 1) {
            split.forEach((part: string, splitIndex: number) => {
              setTimeout(async () => {

                if (split[splitIndex + 1]) {

                  if (!!part) {

                    this.view.history.push(await this.parsePost({
                      mode: 'view',
                      input: `${part}`,
                      post_content: `${part}`,
                      role: 'assistant',
                    }));

                    setTimeout(() => {
                      this.scrollDown();
                    }, 100);
                  }

                } else {
                  this.post.post_content = `${part}`;

                  if (!!part) {
                    this.view.history.push(Object.assign(JSON.parse(JSON.stringify(this.post)), {
                      role: 'assistant',
                    }));

                    setTimeout(() => {
                      this.scrollDown();
                    }, 100);

                  }
                }

              }, (splitIndex) * 300);
            });
          } else {
            this.post.post_content = `${split[0]}`;

            if (!!split[0]) {

              this.view.history.push(Object.assign(JSON.parse(JSON.stringify(this.post)), {
                input: split[0],
                post_content: split[0],
                role: 'assistant',
              }));

              setTimeout(() => {
                this.scrollDown();
              }, 100);

            }
          }

          this.view.aiPrompt = '';
        }

      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  saveDraft(item: post | null = null) {
    item = item || this.post;

    if (!item || !item.name) {
      console.warn('saving draft requires post name', item);
      return false;
    }

    if (!!item.uid) {
      this.update({}, true);
      return false;
    }

    item.active = true;
    item.type = (item.type || 'draft');
    item.post_content = `${item.post_content}`;
    item.user = (item.user || this.userService.getUid());

    this.create({}, true);
  }

  scrollDown() {
    setTimeout(() => {
      try {

        if (!this.historyWrapper || !this.historyWrapper.nativeElement) {
          return false;
        }

        this.historyWrapper.nativeElement.scrollTop = this.historyWrapper.nativeElement.scrollHeight;
      } catch (e) {
        console.warn('scroll down failed', e);
      }
    });
  }

  settings() {
    this.view.settingsView = !this.view.settingsView;
  }

  share() {
    this.aiTools.pickTaskSavePath(this.post, {
      exclude: ['app', 'campaign', 'post', 'website'],
      multiple: true,
    })
      .then((paths: any[]) => {
        if (!!paths) {
          paths.forEach((path: any) => {
            switch (path.uid) {
              case 'image':
                this.pickTaskMediaTemplate(this.post, 'image')
                  .then(() => {
                    this.exportToSavePath(this.post, path.uid);
                  })
                  .catch((error: any) => {
                    if (!!error) {
                      this.events.publish('error', error);
                    }
                  });
                break;
              case 'video':
                this.pickTaskMediaTemplate(this.post, 'video')
                  .then(() => {
                    this.exportToSavePath(this.post, path.uid);
                  })
                  .catch((error: any) => {
                    if (!!error) {
                      this.events.publish('error', error);
                    }
                  });
                break;
              default:
                this.exportToSavePath(this.post, path.uid);
                break;
            }
          });
        }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  showHeaderPopover(event: any = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  startManually() {
    this.view.startManually = true;
  }

  syncComments() {

    if (!this.post || !this.post.uid) {
      return false;
    }

    this.view.loading = true;

    this.commentsService.crawlCommentsByPostUid(this.post.uid)
      .then((response: any) => {
        this.view.loading = false;
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  async toggleLanguage() {

    const modal: any = await this.modalService.create({
      component: EditPostTranslationsModalPage,
      componentProps: {
        post: this.post,
        type: 'product',
      },
      animated: true,
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then((response: any) => {
      const language = response && response.data && response.data.language ? response.data.language : null;

      if (language) {
        this.loadLanguage(language);
      }

    });

    this.modalService.present(modal);
  }

  toggleType(type: any, iType: number) {
    this.view.types[iType].checked = !this.view.types[iType].checked;

    this.calcTypesStates();
  }

  async toolsMenu() {

    this.view.toolsMenuModal = await this.modalService.create({
      backdropDismiss: false,
      component: AiToolsPage,
      componentProps: {
        executeTool: true,
        multiple: false,
        post: this.post,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.view.toolsMenuModal.onWillDismiss().then((response: any) => {
      if (!!response && !!response.data && !!response.data.output && !!response.data.output.length) {
        this.post.post_content = `${response.data.output}`.replace(/\n/g, '<br />');
        this.loadContent();
      }
    });

    this.modalService.present(this.view.toolsMenuModal);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  unbindKeyEvents() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }

  update(params: any = {}, blBackground: boolean = false) {
    this.post.user = this.userService.getUid();

    this.postsService.updatePost(
      Object.assign(this.post, params)
    )
      .then((response: any) => {
        if (!blBackground) {
          this.dismiss(null, 'done');
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  updateLatestHistoryItem() {
    if (!!this.view.history && !!this.view.history.length) {
      this.view.history[this.view.history.length - 1] = this.post;
    }
  }

  useAsPrimary(item: any, index: number) {
    this.view.history = [];

    setTimeout(() => {
      this.view.history = JSON.parse(JSON.stringify([item]));
      this.scrollDown();
    }, 100);
  }

  viewModeChanged(event: any = null) {
    this.calcViewVars();

    if (!!this.view.expertMode) {
      this.loadExpertSidebarData();
    }

  }

}