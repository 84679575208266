import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BlogService } from "src/app/services/pipeline/blog.service";
import { CacheService } from 'src/app/services/core/cache.service';
import { PostsService } from "src/app/services/posts/posts.service";
import { SourcesService } from "src/app/services/pipeline/sources.service";
import { UserService } from 'src/app/services/core/user.service';

import { proxyUrl } from "src/config/variables";
import { LanguageService } from "src/app/services/core/language.service";

@Injectable({
  providedIn: "root",
})
export class ExternalService {

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private cache: CacheService,
    private language: LanguageService,
    private loadingCtrl: LoadingController,
    private posts: PostsService,
    private sources: SourcesService,
    private userService: UserService
  ) {

  }

  addSource(source: any = null) {
    return new Promise(async (resolve, reject) => {
      const alert: any = await this.alertCtrl.create({
        header: "Neue Quelle anlegen",
        subHeader: "Bitte trage die URL zum Blog ein",
        inputs: [
          {
            type: "text",
            name: "title",
            placeholder: "Name des Blogs",
          },
          {
            type: "url",
            name: "url",
            placeholder: "exakte URL, z.B https://www.example.com",
          },
        ],
        buttons: [
          {
            text: "Okay",
            role: "submit",
          },
          {
            text: "Abbrechen",
            role: "cancel",
          },
        ],
      });
      alert.onWillDismiss().then((event: any) => {
        if (event.role === "submit") {
          let title =
            event.data && event.data.values ? event.data.values.title : null,
            url =
              event.data && event.data.values ? event.data.values.url : null;

          if ((url + "").substr(0, 4) !== "http") {
            url = "https://" + url;
          }

          this.verifySupportedURL(url)
            .then(async (response: any) => {
              if (!response.success) {
                reject("error_wp_check_failed");
              } else {
                const loading: any = await this.loadingCtrl.create({
                  spinner: "circular",
                });
                loading.present();

                source = {
                  active: true,
                  name: title,
                  region: "global",
                  url: url,
                };

                let languageInputs = [];

                this.language
                  .getLanguages()
                  .then(async (languages: language[]) => {
                    loading.dismiss();

                    languages.forEach((language: language) => {
                      languageInputs.push({
                        label: language.name,
                        value: language.indent,
                        type: "radio",
                        name: "language",
                      });
                    });

                    const languagesAlert = await this.alertCtrl.create({
                      header: "Sprache",
                      subHeader:
                        "In welcher Sprache veröffentlicht der Blog Inhalte?",
                      inputs: languageInputs,
                      buttons: [
                        {
                          text: "Okay",
                          handler: (language: string) => {
                            source.language = language;
                            loading.present();
                            this.sources
                              .createSource(source)
                              .then((response: any) => {
                                loading.dismiss();
                                resolve(response);
                              })
                              .catch((error: any) => {
                                loading.dismiss();
                                reject(error);
                              });
                          },
                        },
                        {
                          text: "Abbrechen",
                          role: "cancel",
                        },
                      ],
                    });

                    await languagesAlert.present();
                  })
                  .catch((error: any) => {
                    loading.dismiss();
                    reject(error);
                  });
              }
            })
            .catch(reject);
        } else {
          reject();
        }
      });
      await alert.present();
    });
  }

  asCols() {
    return new Promise((resolve, reject) => {
      this.getAll()
        .then((rawPosts: any) => {
          rawPosts.forEach((rawPost: any) => {
            rawPost = this.posts.getFullPost(rawPost);
          });
          resolve(rawPosts);
        })
        .catch(reject);
    });
  }

  deleteSource(sourceId: number) {
    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loadingCtrl.create({
        spinner: "circular",
      });
      loading.present();

      this.sources
        .deleteSource(sourceId)
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.blog
        .getSelfHosted({
          active: false,
        })
        .then((posts: any) => {
          if (posts) {
            posts.forEach((post: any, index: number) => {
              posts[index] = this.posts.getFullPost(post);
            });
          }
          resolve(posts);
        })
        .catch(reject);
    });
  }

  getByHost(hostUrl: string, iEntries: number = 25) {
    return new Promise(async (resolve, reject) => {

      if ((`${hostUrl || ''}`).substr(0, 4) !== "http") {
        hostUrl = `https://${hostUrl}`;
      }

      const url: string = encodeURI(`${proxyUrl}${hostUrl}/wp-json/wp/v2/posts?_embed&per_page=${iEntries || 10}`);
      const key: string = `external_host_${url}`;
      const fromCache: cacheItem = await this.cache.get(key, 30 * 60);

      if (fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadUrl(url)
          .then((response: any) => {
            let items = response;
            items.forEach((item: any) => {
              item = this.posts.getFullPost(item);
            });
            resolve(items);
            this.cache.set(key, items);
          })
          .catch(reject);
      }
    });
  }

  getSelfHostedByHost(
    hostUrl: string,
    iEntries: number = 25,
    options: any = {}
  ) {
    return this.blog.getSelfHosted(
      Object.assign(options, {
        host: hostUrl,
      }),
      false
    );
  }

  getSources() {
    return new Promise((resolve, reject) => {
      this.sources
        .getSources(false, {
          active: true,
        })
        .then((sources: any) => {
          console.log("raw sources from server", sources);
          resolve(sources);
        })
        .catch(reject);
    });
  }

  reject(post: post) {
    return this.posts.rejectPost(post.uid);
  }

  submitSource(source: source) {
    source.active = false;
    source.user = this.userService.getUid();

    return this.sources.createSource(source);
  }

  verifySupportedShopURL(url: string, blBackground: boolean = false) {
    return this.validateBlog(url, blBackground);
  }

  verifySupportedURL(url: string, blBackground: boolean = false, options: any = {}) {
    return this.validateBlog(url, blBackground, options);
  }

  validateBlog(url: string, blBackground: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let loading: any;

      if (!blBackground) {
        loading = await this.loadingCtrl.create({
          spinner: "circular",
        });
        loading.present();
      }

      if (url && url.indexOf("http") === -1) {
        url = "https://" + url;
      }

      let sslUrl = url
        .replace("http://", "")
        .replace("https://", "http://")
        .replace("http://", "https://");
      url = sslUrl.replace("https://", "http://");

      let urls = [
        sslUrl,
        sslUrl.replace("://", "://www."),
        url,
        url.replace("://", "://www."),
      ];

      this.AppCMS.loadPluginData(
        "pipeline",
        Object.assign(options, {
          url: url,
        }),
        ["validation", "blog"]
      )
        .then((response: any) => {

          if (!blBackground && loading) {
            loading.dismiss();
          }

          resolve({
            data: response,
            success: !!(response && (response.success || response[0])),
          });
        })
        .catch((error: any) => {
          if (!blBackground && loading) {
            loading.dismiss();
          }
          reject(error);
        });
    });
  }

}