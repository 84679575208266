<ion-grid>

  <!-- header -->
  <ion-row [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + 50) + 'px' : '100%')"
    [class.has-multiple-cols]="!!view.dataKeys && (view.dataKeys.length > 1)"
    *ngIf="!!view.rowMappings && !!view.dataKeys">

    <!-- select all -->
    <ion-col>
      <ion-item lines="none">
        <ion-checkbox [(ngModel)]="view.select_all" (ionChange)="selectAll($event)"></ion-checkbox>
      </ion-item>
    </ion-col>

    <!-- mappings -->
    <ion-col *ngFor="let key of view.dataKeys; let i = index">
      <ion-item lines="none">
        <ion-select interface="popover" [okText]="'okay' | translate" [cancelText]="'cancel' | translate"
          [placeholder]="'field' | translate" [(ngModel)]="view.rowMappings[i]">
          <ion-select-option *ngFor="let key of view.mappingKeys" [innerHTML]="key|translate"
            [value]="key"></ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>

  </ion-row>

  <!-- list -->
  <ion-row *ngFor="let item of items"
    [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + 50) + 'px' : '100%')"
    [class.has-multiple-cols]="!!view.dataKeys && (view.dataKeys.length > 1)">

    <ion-col>
      <ion-item lines="none">
        <ion-checkbox [(ngModel)]="item._checked" (ionChange)="onItemChecked(item, $event)"></ion-checkbox>
      </ion-item>
    </ion-col>

    <ion-col *ngFor="let key of view.dataKeys">
      <ion-item lines="none" class="ion-text-wrap">
        <ion-label class="ion-text-wrap">
          <small [innerHTML]="item[key]"></small>
        </ion-label>
      </ion-item>
    </ion-col>

  </ion-row>

</ion-grid>