import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from "src/app/services/ai/ai-worker.service";

import { EventsService } from "src/app/services/core/events.service";
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-ai-settings-card',
  templateUrl: './ai-settings-card.component.html',
  styleUrls: ['./ai-settings-card.component.scss']
})
export class AiSettingsCardComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('infoPopover') infoPopover: any;

  @Input() cards: any = {};
  @Input() config: any;
  @Input() options: aiSettingsOptions;
  @Input() view: any = {};

  @Output() changed = new EventEmitter();

  contexts: aiContext[] = [];

  fallbackImg: string = './assets/img/fallback.webp';

  isInfoPopoverOpen: boolean = false;

  operations: string[] = [
    'image_to_video',
    'text_generation',
    'text_to_image',
  ];

  precisions: string[] = [
    'bf16',
    'fp4',
    'fp8',
    'fp16',
    'fp32',
  ];

  samplers: any[];

  shouldShow: any = {};

  constructor(
    public aiTools: AiToolsService,
    public aiWorker: AiWorkerService,

    private events: EventsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
  ) {
  }

  calcShouldShowOperationVars() {
    if (!!this.operations && !!this.operations.length) {
      this.operations.forEach((operation: string) => {
        this.shouldShow[operation] = (!this.options || !this.options.operations || (this.options.operations.indexOf(operation) !== -1));
      });
    }
  }

  calcViewVars() {
    this.config = this.config || {};
    this.config.provider = this.config.provider || 'getgenius';

    if (!this.config.hasOwnProperty('temperature')) {
      this.config.temperature = 1;
    }

    if (!this.view.hasOwnProperty('samplers')) {
      this.loadSamplers();
    }

    this.calcShouldShowOperationVars();
  }

  chooseModel(key: string = 'models', multiple: boolean = true, category: string | null = null) {

    if (key === 'models' && !multiple) {
      key = 'model';
    } else
      if (key === 'model' && !!multiple) {
        key = 'models';
      }

    this.aiTools.chooseModel({
      filter: {
        category: category,
      },
      multiple: !!multiple,
    })
      .then((response: any) => {
        const data: any[] = (!!response.uid ? [response] : (!multiple ? [response.item] : (!!response && !!response.items ? response.items : (!!response.item ? [response.item] : []))));

        if (!!data) {
          this.config[key] = data;
          this.aiWorker.setConfig(this.config);
          this.onChanged();
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  chooseProvider() {
    this.aiTools.chooseProvider()
      .then((provider: any) => {
        if (!!provider) {
          this.config.provider = provider;
          this.aiWorker.setConfig(this.config);
          this.onChanged();
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  initContexts() {
    const contexts: aiContext[] = this.aiTools.getContexts();
    this.contexts = (contexts || []);
  }

  loadSamplers() {
    try {
      this.samplers = [
        {
          name: 'automatically',
          uid: 'auto',
        }
      ].concat(this.sd.getSamplers());
    } catch (e) {
      console.warn('loading samplers failed', e);
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initContexts();
    this.calcViewVars();
  }

  onChanged(event: any = null) {
    this.changed.emit(this.config || {});
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

}