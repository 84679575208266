import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { BreadcrumbsService } from 'src/app/services/utils/breadcrumbs.service';

@Component({
  selector: 'pipeline-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() config: any;
  @Input() view: any;

  @Output() onBreadcrumbsButtonClick = new EventEmitter();
  @Output() onBreadcrumbsConfigUpdated = new EventEmitter();

  buttons: button[] = [];

  constructor(
    private events: EventsService,
    private breadcrumbsService: BreadcrumbsService,
  ) {
  }

  public async calculate() {
    try {

      const calc: any = await this.breadcrumbsService.calculateConfig(this.view || {}, this.config || {});

      if (!calc) {
        return false;
      }

      if (calc.hasOwnProperty('buttons')) {
        this.buttons = (calc.buttons || []);
      }

      if (!!calc.config) {
        this.config = calc.config;
      }

      if (!!calc.view) {
        this.view = calc.view;
      }

      return this.view;
    } catch (e) {
      console.warn('calc failed', e);
      return this.view;
    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.breadcrumbsButtonsChanged = this.events.subscribe('breadcrumbs:buttons:changed', (buttons: button[]) => {
      this.buttons = (buttons || []);
    });
  }


  ngOnInit() {
    this.initEvents();
    this.calculate();
  }

  async onButtonClick(button: button) {
    this.view.loading = true;
    this.config = this.config || {};
    this.config.offset = button.index;

    await this.calculate();

    setTimeout(() => {
      this.view.loading = false;
    });
  }

  onLimitChanged(event: any = null) {
    this.config.offset = 0;
    this.calculate();
  }

  async paginateBack() {
    try {
      this.view.loading = true;

      const calc: any = await this.breadcrumbsService.calculateBack(this.view || {}, this.config || {});

      if (!calc) {
        return false;
      }

      if (calc.hasOwnProperty('buttons')) {
        this.buttons = (calc.buttons || []);
      }

      if (!!calc.config) {
        this.config = calc.config;
      }

      if (!!calc.view) {
        this.view = calc.view;
      }

      this.view.loading = false;
    } catch (e) {
      console.warn('breadcrumbs: calc back failed', e);
      this.view.loading = false;
    }
  }

  async paginateNext() {
    try {
      this.view.loading = true;

      const calc: any = await this.breadcrumbsService.calculateNext(this.view || {}, this.config || {});

      if (!calc) {
        return false;
      }

      if (calc.hasOwnProperty('buttons')) {
        this.buttons = (calc.buttons || []);
      }

      if (!!calc.config) {
        this.config = calc.config;
      }

      if (!!calc.view) {
        this.view = calc.view;
      }

      this.view.loading = false;
    } catch (e) {
      console.warn('breadcrumbs: calc next failed', e);
      this.view.loading = false;
    }
  }

}