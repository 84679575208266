import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TargetgroupsService } from "src/app/services/target-groups/target-groups.service";
import { ViewService } from 'src/app/services/core/view.service';

import { CreateTargetGroupPage } from '../create-target-group/create-target-group.page';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-target-groups',
  templateUrl: './target-groups.page.html',
  styleUrls: ['./target-groups.page.scss'],
})
export class TargetGroupsPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  search: searchOptions = {
    itemsKey: 'target_groups',
    keys: ['title', 'excerpt', 'url', 'label', 'min_age', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'target_groups_top_card',
      text: 'target_groups_top_card_text',
      title: 'target_groups_top_card_title',
    },
    itemSize: 64,
    key: ['name', 'url'],
    title: 'target_groups',
    viewMode: 'list',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private targetGroups: TargetgroupsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async create() {

    const createTargetGroupModal: any = await this.modalService.create({
      component: CreateTargetGroupPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    createTargetGroupModal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(createTargetGroupModal);
  }

  delete(targetGroup: targetGroup) {
    this.targetGroups.delete(targetGroup.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  detectChanges() {

  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any = null) {
    this.loadTargetGroups(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (event) {
          event.target.complete();
        }
      });
  }

  async export(event: any = null, options: any = {}) {
    this.exportService.exportUsingUI({
      data: (this.view.target_groups || []),
      service: this.targetGroups,
      source: 'target_groups',
      type: 'target_group',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.view.isModal = this.modalService.isModal();
    this.loadTargetGroups();
  }

  async loadProject() {
    this.view.project = await this.projectsService.getCurrent();
  }

  async loadTargetGroups(blForceRefresh: boolean = false) {
    this.targetGroups.getAll(blForceRefresh)
      .then((targetGroups: targetGroup[]) => {
        this.view.target_groups = targetGroups;
      })
      .catch((error: any) => {
        this.view.target_groups = [];
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();
    this.loadProject();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  onSearchChanged(searchOptions: any = null) {
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  toggleActive(targetGroup: targetGroup) {
    targetGroup.active = !targetGroup.active;

    this.targetGroups.update(targetGroup)
      .catch((error: any) => {
        this.events.publish('error', error);
        targetGroup.active = !targetGroup.active;
      });
  }

  async viewGroup(targetGroup: targetGroup) {

    const editTargetGroupModal: any = await this.modalService.create({
      component: CreateTargetGroupPage,
      componentProps: {
        targetGroup: targetGroup,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    editTargetGroupModal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(editTargetGroupModal);
  }

}
