import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent implements OnInit {
  @Input() buttons: button[];
  @Input() method: string;
  @Input() mode: string;
  @Input() options: tableViewOptions;
  @Input() service: any;
  @Input() view: any;
  @Input() items: any[];

  @Output() itemsChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  paginationConfig: paginationConfig = {

  };

  constructor(
    private alertService: AlertService,
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  calcSelectedItems(item: any | null = null) {

    if (this.view.items && this.view.items.length) {
      this.view.selectedItems = this.view.items.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.itemsChanged.emit(this.view.selectedItems);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    if (!this.view.hasOwnProperty('dataKeys') && !!this.items && !!this.items[0]) {
      this.view.dataKeys = Object.keys(this.items[0]);
    }

    if (!this.view.hasOwnProperty('mappingKeys') && !!this.view.dataKeys) {
      this.view.mappingKeys = this.view.dataKeys;
    }

    if (!this.view.hasOwnProperty('rowMappings') && !!this.view.dataKeys) {
      this.view.rowMappings = this.view.dataKeys;
    }
    
    this.view.previewGridWidth = (this.view.dataKeys.length * 200);
  }

  doRefresh(event: any = null) {
    try {
      this.loadData(true);
    } catch (e) {
      this.events.publish('error', e);
      console.warn('refreshing failed (1)', e);
    }
  }

  loadData(blForceRefresh: boolean = false) {

    if (!this.method) {
      console.warn('dynamic view: method not defined');
      return false;
    } else
      if (!this.service) {
        console.warn('dynamic view: service not defined');
        return false;
      }

    try {
      this.view.loading = true;

      let params: any = JSON.parse(JSON.stringify((!!this.options && !!this.options.params ? this.options.params || {} : {})));

      this.service[this.method](params, blForceRefresh)
        .then((response: any) => {
          this.items = (response || []);
          this.view.loading = false;

          this.itemsChanged.emit(this.items);
        })
        .catch((error: any) => {
          this.view.loading = false;
          this.events.publish('error', error);
        });
    } catch (e) {
      this.events.publish('error', e);
    }

  }

  ngOnInit() {
    this.options = this.options || {};
    this.options.labelKey = this.options.labelKey || 'label';

    this.calcViewVars();
    this.validateUIState();
  }

  onButtonClick(button: button, item: any) {

    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  onItemChecked(item: any, event: any = null) {
    this.calcSelectedItems();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  selectAll(event: any = null) {

    if (!this.items || !this.items.length) {
      return false;
    }

    this.items.forEach((item: any) => {
      item._checked = event.detail.value === 'on';
    });

    this.calcSelectedItems();
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;

    if (!!this.options.photoKey) {
      item[this.options.photoKey] = item.photo;
    }
  }

  toggleActive(item: any, event: any = null) {
    console.log('toggleActive', item);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  validateUIState() {
    if (!this.items || !this.items.length) {
      this.loadData();
    }
  }

}