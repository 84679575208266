import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { hostUrl } from 'src/config/variables';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { MenuService } from 'src/app/services/core/menu.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

import { createAction, props } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _connectWithSIWAData: any;

  _detailItem: user | null;

  activeProfile: user;

  activeProfileId: number;

  addressList: any[] = window.localStorage.getItem('addressList') ? JSON.parse(window.localStorage.getItem('addressList')) : [];

  afterLoginRedirectUrl: string = '/dashboard';

  billingInformation: any = {};

  blConnectWithSIWA: boolean = false;

  defaultAvatarUrls: any = {
    m: './assets/img/avatars/m/light/1.png',
    w: './assets/img/avatars/w/light/2.png',
    d: './assets/img/avatars/d/light/3.png',
  };

  deliveryKeys: any = window.localStorage.getItem('deliveryKeys') ? JSON.parse(window.localStorage.getItem('deliveryKeys')) : {};

  genders: string[] = ['m', 'w', 'd'];

  registerRedirectUri: string = '/interests';

  user: user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : {};

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private chooser: ChooserService,
    private configService: ConfigService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private tools: ToolsService,
    private menu: MenuService,
    private translate: TranslateService,
  ) {
  }

  addAddress(address: any) {
    if (!this.inAddressList(address)) {
      this.addressList.push(address);
      window.localStorage.setItem('addressList', JSON.stringify(this.addressList));
    }
  }

  changePassword(passwordData: passwordData) {
    return new Promise((resolve, reject) => {
      let user = this.getUser(), email = this.getEmail();
      user.classifications = user.classifications || {};
      user.classifications.allow_password_reset = true;

      this.update(user)
        .then(() => {
          if (!email || !email.length) {
            reject('error_missing_user_email');
          } else {
            this.AppCMS.loadPluginData('user', {
              email: email,
              data: passwordData,
            }, ['setNewPassword'])
              .then((response: any) => {
                resolve(response);
              })
              .catch(reject);
          }
        })
        .catch(reject);
    });
  }

  checkUsername(nickname: string) {
    return new Promise((resolve, reject) => {
      const user: user = this.getUser();

      if (!nickname.match(/^[a-zA-Z0-9]+$/)) {
        reject('error_username_invalid_characters');
      } else
        if (user && (user.nickname === nickname)) {
          resolve({ success: true });
        } else {
          this.AppCMS.loadPluginData('user', {
            nickname: nickname,
          }, ['validateNickname'])
            .then(resolve).catch(reject);
        }
    });
  }

  cleanClassifications(classifications: userClassifications) {
    classifications = JSON.parse(JSON.stringify(classifications));

    if (classifications.settings) {
      if (classifications.settings.woo_tabs) {
        classifications.settings.woo_tabs.forEach((tab: tab, index: number) => {
          delete tab.streamlineIcon;
        });
      }
    }

    return classifications;
  }

  async connectWithSIWA(options: any = {}, step: number = 1) {
    return new Promise(async (resolve, reject) => {
      switch (step) {
        case 1:
          this.translate.get([
            'login',
            'cancel',
            'create_account',
            'connect_account_with_siwa',
            'connect_account_with_siwa_text'
          ]).subscribe((res: any) => {

            const response: any = {
              user: options,
            };

            this._connectWithSIWAData = options;
            this.blConnectWithSIWA = true;

            this.events.publish('alert', {
              header: res.connect_account_with_siwa || 'connect_account_with_siwa',
              message: res.connect_account_with_siwa_text || 'connect_account_with_siwa_text',
              buttons: [
                {
                  text: res.login,
                  handler: () => {
                    response.action = 'connectWithSIWA';
                    resolve(response);
                  },
                },
                {
                  text: res.create_account,
                  handler: () => {
                    response.action = 'createSIWAAccount';
                    this.createSIWAAccount(options)
                      .then(() => {
                        resolve(response);
                      }).catch(reject);
                  },
                },
                {
                  text: res.cancel,
                  role: 'cancel',
                  handler: () => {
                    response.action = 'cancel';
                    resolve(response);
                  },
                }
              ]
            });
          });
          break;
        case 2:
          this.AppCMS.loadPluginData('user', {
            uid: this.getUid(),
            identityToken: this._connectWithSIWAData.user,
          }, ['connectWithSIWA'])
            .then(resolve)
            .catch(reject);
          break;
      }
    });
  }

  create(userData: user, blSet: boolean = true) {
    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      this.AppCMS.postPluginData('customers', 'createNewCustomer', { customer: userData })
        .then((response: any) => {
          loading.dismiss();

          if (response.success) {
            let user: user = (response.user || response.customer);

            if (!!user.uid) {
              user.password = userData.password;

              if (!!blSet) {
                this.setUser(user);
              }
            }

            resolve(response);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  createSIWAAccount(userData: any) {
    return new Promise((resolve, reject) => {
      const nickname: string = 'user' + Math.floor(100000 + Math.random() * 900000);

      const registerData: any = {
        firstname: (userData.fullName ? userData.fullName.givenName || userData.firstname : userData.firstname) || '-',
        lastname: (userData.fullName ? userData.fullName.familyName || userData.lastname : userData.lastname) || '-',
        email: userData.email || (nickname + '@pipeline.page'),
        identity_token: (userData.user || userData.identityToken) || userData.identity_token,
        nickname: nickname,
        classifications: userData.classifications,
        password: (userData.user || userData.identityToken) || userData.identity_token,
        password_repeat: (userData.user || userData.identityToken) || userData.identity_token,
        plz: '-',
        phone: '-',
        street: '-',
        streetNo: '-',
      };

      this.register(registerData)
        .then(() => {
          resolve(userData);
        })
        .catch(reject);
    });
  }

  createUserGroup(userGroup: any) {
    return this.AppCMS.loadPluginData('customers', { group: userGroup }, ['user_groups', 'create']);
  }

  deleteAccount(userData: user) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      this.AppCMS.postPluginData('user', 'delete', { uid: userData.uid })
        .then((response: any) => {
          loading.dismiss();

          if (response.success) {
            this.logout().then(resolve).catch(reject);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }

        })
        .catch(error => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  detailItem(item: user | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getActiveProfile() {
    const user = this.getUser() || {};
    if (!this.activeProfile && user && user.uid) {
      return this.getFullUser(user);
    }
    return this.getFullUser(this.activeProfile);
  }

  getActiveProfileId() {
    return this.activeProfileId;
  }

  getAddressList() {
    return this.addressList;
  }

  getAfterLoginRedirectUrl() {
    const config: any = this.configService.getConfig() || {};
    return config.loginRedirectUrl || this.afterLoginRedirectUrl;
  }

  getAll(options: any = null, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'users_all', fromCache: cacheItem = await this.cache.get(key, 60 * 60)
      if (!blForceRefresh && fromCache && fromCache.data) {
        resolve(
          this.parseUsers(fromCache.data)
        );
      } else {
        this.AppCMS.loadPluginData('user', {}, ['public'])
          .then((allUsers: any) => {
            allUsers = this.parseUsers(allUsers);
            this.cache.set(key, allUsers);
            resolve(allUsers);
          })
          .catch(reject);
      }
    });
  }

  getApiCredentials() {
    const user = this.getUser(true) || {};
    return this.AppCMS.getApiCredentials(user);
  }

  getAvatarUrl(user: user | null = null, blSkipPhoto: boolean = false) {
    user = user || (this.getUser() || {});

    const gender = this.getGender(user);

    if (user.photo && user.photo.indexOf('avatar') !== -1 && !blSkipPhoto) {
      return user.photo;
    }

    const avatarUrl = this.defaultAvatarUrls[gender] || this.defaultAvatarUrls['d'];

    return avatarUrl;
  }

  getBillingInformation(data: any = {}) {
    let user = this.getUser() || {};

    if (!user.firstname) {
      user.firstname = this.getDeliveryKey('first_name') || '';
    }

    if (!user.lastname) {
      user.lastname = this.getDeliveryKey('last_name') || '';
    }

    if (!user.city) {
      user.city = this.getDeliveryKey('city') || '';
    }

    if (!user.plz) {
      user.plz = this.billingInformation.plz || (this.getDeliveryKey('postcode') || '');
    }

    let _user = Object.assign({
      first_name: user.firstname || '-',
      last_name: user.lastname || '-',
      address_1: this.billingInformation.address_1 || (user.street + ' ' + user.streetNo),
      address_2: this.billingInformation.address_2 || '',
      city: this.billingInformation.city || user.city,
      postcode: user.plz || '',
      country: user.country || 'DE',
      email: user.email || '',
      phone: user.phone || '',
    }, data);

    delete _user.password;

    return _user;
  }

  getByClassification(Key: string, Value: any, forceReload: boolean = false) {
    return this.AppCMS.loadPluginData('classification', {}, ['customers', Key, Value]);
  };

  getByUid(uid: any, blForceRefresh: boolean = false, blExpanded: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'user_' + uid,
        fromCache: cacheItem = await this.cache.get(key, 30 * 60);

      if (!uid) {
        reject('error_missing_uid');
      } else
        if (fromCache && fromCache.data && !blForceRefresh) {
          resolve(fromCache.data);
        } else {
          this.AppCMS.loadPluginData('user', Object.assign(options, {
            expanded: blExpanded,
            user: this.getApiCredentials(),
          }), [uid])
            .then((user: user) => {
              if (user && (user.uid == uid)) {
                user = this.getFullUser(user);
                this.cache.set(key, user);
                resolve(user);
              } else {
                reject(user);
              }
            })
            .catch(reject);
        }
    });
  };

  getCity() {
    return (this.getUser() || {}).city;
  }

  getClassification(classification: string) {
    const user = this.getUser(), classifications = user.classifications || {};
    return classifications && classifications.hasOwnProperty(classification) ? classifications[classification] : null;
  }

  getCoverImage(user: user) {
    const coverImg = user.classifications && user.classifications.coverImage && user.classifications.coverImage.length && typeof user.classifications.coverImage === 'string' ? user.classifications.coverImage : null;
    return coverImg || (user.classifications && user.classifications.cover && user.classifications && typeof user.classifications.cover === 'string' ? user.classifications && user.classifications.cover : null);
  }

  getDeliveryKey(key: string) {
    return this.deliveryKeys[key] || null;
  }

  getDisplayName(person: user) {
    let displayName: string;
    if (person && person.classifications && person.classifications.displayName && person.classifications.displayName.length) {
      displayName = person.classifications.displayName;
    } else
      if (this.isType('Blog', person)) {
        displayName = person.nickname;
      } else
        if (
          (person.firstname !== '-' && person.firstname.length >= 2) &&
          (person.lastname !== '-' && person.lastname.length >= 2)
        ) {
          displayName = `${person.firstname} ${person.lastname[0]}.`;
        } else {
          displayName = `@${person.nickname}`;
        }
    return displayName;
  }

  getEmail() {
    return (this.getUser() || {}).email;
  }

  getFirstname() {
    return (this.getUser() || {}).firstname;
  }

  getFullUser(user: user) {

    if (user) {
      user = JSON.parse(JSON.stringify(user));

      const url = hostUrl;

      user.active = !!user.active;
      user.public = !!user.public;

      user.score = user.score ? parseInt(user.score + '') : 0;
      user.uid = user.uid ? parseInt(user.uid + '') : null;

      if (user.photo && user.photo[0] === "/") {
        user.photo = url + user.photo;
      }

      if (user.photo && user.photo.length) {
        user.photo = (user.photo || '').replace('https://api.tie.21medien.net', url);
      } else {
        user.photo = this.getAvatarUrl(user);
      }

      delete user.isFriend;
      delete user.me;

      if (user.firstname && user.firstname === '-') {
        delete user.firstname;
      }

      if (user.lastname && user.lastname === '-') {
        delete user.lastname;
      }

      if (!user.uid) {
        delete user.password;
        delete user.nickname;
      }

    }

    return user;
  }

  getGender(user: user | null = null) {
    user = (user || this.getUser()) || {};

    let gender: string;

    switch (user.title) {
      case 'Herr': gender = 'm'; break;
      case 'Herr Dr.': gender = 'm'; break;
      case 'Frau': gender = 'w'; break;
      case 'Divers': gender = 'd'; break;
    }

    return gender;
  }

  getGenders() {
    return this.genders;
  }

  getLanguage() {
    let deviceLanguage = (window.navigator.language || 'en').split('-')[0];
    return (
      this.user && this.user.classifications && this.user.classifications.language ?
        this.user.classifications.language :
        (deviceLanguage || 'en')
    );
  }

  getLastname() {
    return (this.getUser() || {}).lastname;
  }

  getPublic(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'users_public', fromCache: cacheItem = await this.cache.get(key, 60 * 60)
      if (!blForceRefresh && fromCache && fromCache.data) {
        resolve(
          this.parseUsers(fromCache.data)
        );
      } else {
        this.getAll()
          .then((publicUsers: user[]) => {

            if (publicUsers && publicUsers.length) {
              publicUsers = publicUsers.filter((publicUser: user) => {
                return !!(publicUser && publicUser.classifications && publicUser.classifications.public);
              });
            }

            publicUsers = this.parseUsers(publicUsers);
            this.cache.set(key, publicUsers);
            resolve(publicUsers);
          })
          .catch(reject);
      }
    });
  }

  getPlz() {
    return this.getDeliveryKey('postcode') || (this.getUser() ? this.getUser().plz : null);
  }

  async getRandomNickname() {
    return ("user" + Math.floor(100000 + Math.random() * 900000));
  }

  getRegisterRedirectUri() {
    const locationParams = new URLSearchParams(location.search),
      redirectUriFromParams = locationParams.get('redirect_uri');

    if (!!this.configService.getConfigParam('forceAbonnementAfterRegistration')) {
      return '/abonnements';
    }

    if (!!redirectUriFromParams) {
      return redirectUriFromParams;
    }

    const redirectUriFromConfig: string = this.configService.getConfigParam('registerRedirectUri');

    if (!!redirectUriFromConfig) {
      return redirectUriFromConfig;
    }

    if (this.configService.useInterests()) {
      return '/interests';
    }

    return this.configService.getRoute('home');
  }

  getScore(user: user | null = null) {
    user = user || this.getUser();
    return (user.score || 0);
  }

  getType(user: user | null = null) {
    user = user || this.getUser();
    return user && user.classifications && user.classifications.type && user.classifications.type ? user.classifications.type : null;
  }

  getUser(withPassword = false) {
    let user: user = Object.assign({}, this.user);

    if (!user.phone || user.phone == '') {
      user.phone = this.getDeliveryKey('phone');
    }

    if (!withPassword) {
      delete user.password;
    }

    if (user.hasOwnProperty('classifications')) {
      user.classifications = JSON.parse(JSON.stringify(user.classifications || {}));

      const blEmptyClassificationsArray = typeof user.classifications !== 'undefined' && user.classifications.length === 0;

      if (blEmptyClassificationsArray) {
        user.classifications = {
          type: 'Nutzer',
        };
      }
    }

    if (user && user.classifications) {
      //user.classifications.type = 'Admin';
    }

    return this.getFullUser(user);
  }

  getUserGroups(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('customers', {}, ['user_groups'], null, blForceRefresh);
  }

  getUid(user: user | null = null) {
    user = user || (this.getUser() || {});
    return this.AppCMS.getUserUid(user);
  }

  inAddressList(address: any) {
    if (this.addressList && this.addressList.length) {
      const blMatch = this.addressList.filter((_address: any) => {
        return _address.route === address.route &&
          _address.street_number === address.street_number &&
          _address.postal_code === address.postal_code;
      });
      return !!blMatch.length;
    }
    return false;
  }

  isLoggedIn(user: user | null = null) {
    const uid: number = this.getUid(user);
    return (!!uid && (uid !== -1));
  }

  isType(type: string | string[], user: user | null = null) {
    const userType: string = this.getType(user);

    if (typeof type === 'object') {
      return type.indexOf(userType) !== -1;
    }

    return userType === type;
  }

  loginWithAppleSignIn(data: any) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      this.AppCMS.postPluginData('user', 'loginWithAppleSignIn', {
        user: data,
        expanded: false,
      })
        .then((response: any) => {
          loading.dismiss();
          if (response.success) {
            this.events.publish('appcms:user:loggedin');
            this.setUser(response.user);
            resolve(response);
          } else
            if (response && response.code) {
              switch (response.code) {
                case 'error_user_byIdentityToken_not_found':
                  this.connectWithSIWA(data).then(resolve).catch(reject);
                  break;
              }
            } else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
        })
        .catch((error: any) => {
          loading.dismiss();

          if (error && error.code) {
            switch (error.code) {
              case 'error_user_byIdentityToken_not_found':
                this.connectWithSIWA(data).then(resolve).catch(reject);
                break;
            }
          } else {
            reject(error);
          }

        });
    });
  }

  logout(user: user | null = null) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      this.setUser({})
        .then(() => {
          this.menu.close(true);
          loading.dismiss();

          this.events.publish('appcms:user:loggedout');
          resolve({});
        })
        .catch((e: any) => {
          loading.dismiss();
          reject(e);
        });
    });
  }

  parseUsers(users: user[], blShuffle: boolean = true) {

    users.forEach((user: user) => {
      user = this.getFullUser(user);
      this.cache.set('user_' + user.uid, user);
    });

    if (blShuffle) {
      users = this.tools.shuffle(users);
    }

    return users;
  }

  pick(options: chooseConfig = null) {
    return new Promise(async (resolve, reject) => {

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        labelKey: 'email',
        service: this,
        subLabelKey: 'nickname',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  register(userData: user) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      userData.firstname = this.tools.capitalize(userData.firstname);
      userData.lastname = this.tools.capitalize(userData.lastname);

      userData.active = true;
      userData.public = false;
      userData.score = userData.score || 0;
      userData.nickname = userData.nickname || (await this.getRandomNickname());

      this.AppCMS.postPluginData('user', 'register', { user: userData })
        .then((response: any) => {
          const user: user = (response.user || response.customer);

          loading.dismiss();

          if (user && response.success) {
            this.setUser(user).then(resolve).catch(reject);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  requestNewPassword(user: user) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      this.AppCMS.loadPluginData('user', {
        user: user,
        email: user.email
      }, ['requestNewPassword'])
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  public search(query: string, filter: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('user', Object.assign(filter, {
        query: query,
        like: true,
        returnIfNotKeyExists: true,
      }), ['search'], null, blForceRefresh)
        .then((users: user[]) => {

          if (users && users.length) {
            users.forEach((user: user) => {
              user = this.getFullUser(user);
            });
          }

          resolve(users);
        })
        .catch(reject);
    });
  }

  setActiveProfile(profile: any) {
    profile = JSON.parse(JSON.stringify(profile));

    if (profile && profile.avatar) {
      profile.photo = profile.avatar;
      delete profile.avatar;
    }

    if (profile && profile.name) {
      profile.nickname = profile.name;
      delete profile.name;
    }

    this.activeProfile = profile as user;
    this.setActiveProfileId(profile ? profile.uid : null);
  }

  setActiveProfileId(profileId: number) {
    this.activeProfileId = profileId;
  }

  setAfterLoginRedirectUrl(url: string) {
    this.afterLoginRedirectUrl = url;
  }

  setAfterRegisterRedirectUrl(registerRedirectUri: string) {
    this.registerRedirectUri = registerRedirectUri;
  }

  setBillingInformation(key: string, value: any) {
    this.billingInformation[key] = value;
  }

  setClassification(key: string, value: any, user: user | null = null) {
    user = user || this.getUser();
    user.classifications = user.classifications || {};
    user.classifications[key] = value;

    return this.setUser(user, true);
  }

  setClassifications(classifications: object, user: user | null = null) {
    user = user || this.getUser();
    user.classifications = user.classifications || {};

    const keys: string[] = Object.keys(classifications);

    keys.forEach((key: string) => {
      user.classifications[key] = classifications[key];
    });

    return this.setUser(user, true);
  }

  setDeliveryKey(key: string, value: string) {
    this.deliveryKeys[key] = value;
    window.localStorage.setItem('deliveryKeys', JSON.stringify(this.deliveryKeys));
  }

  setScore(iScore: number, blSync: boolean = true) {
    let user = this.getUser();
    user.score = iScore;
    return this.setUser(user, blSync);
  }

  setStreet(street: string, blSync: boolean = true) {
    let user = this.getUser();
    user.street = street;

    return this.setUser(user, blSync);
  }

  setStreetNo(streetNo: string, blSync: boolean = true) {
    let user = this.getUser();
    user.streetNo = streetNo;

    return this.setUser(user, blSync);
  }

  setUser(user: user, blSync: boolean = false) {
    return new Promise((resolve, reject) => {

      if (user && (typeof user === 'object')) {
        user = this.getFullUser(JSON.parse(JSON.stringify(user)));
        delete user.location;
        delete user.logfile;
        delete user.token;
        delete user.identity_token;
      }

      this.user = user;
      window.localStorage.setItem('appcmsUser', JSON.stringify(user));

      if (user && user.uid) {
        this.cache.set('user_' + user.uid, user);
      }

      this.events.publish('appcms:user:updated', user);

      if (blSync && (user && user.uid)) {
        this.syncUser()
          .then(() => {
            resolve(this.getUser());
          })
          .catch(reject);
      } else {
        resolve(this.getUser());
      }
    });
  }

  async showRedeemCodeButton(bl: boolean | null = null) {
    const key: string = 'bl_shouldShowRedeemCodeButton';
    if (bl !== null) {
      return this.cache.set(key, bl);
    } else {
      const blShouldShow: cacheItem = await this.cache.get(key, 60 * 60 * 24 * 7);
      return !!(blShouldShow && blShouldShow.data);
    }
  }

  shouldConnectWithSIWA() {
    return this.blConnectWithSIWA;
  }

  async shouldValidate(bl: boolean | null = null) {

    if (bl !== null) {
      this.cache.set('user_shouldValidate', bl);
      return true;
    }

    const should: cacheItem = await this.cache.get('user_shouldValidate', (60 * 60 * 24 * 7));

    return should && (should.data !== null) ? !!should.data : true;
  }

  syncUser() {
    if (this.user.uid) {
      return this.update(this.user);
    }
  }

  update(user: user) {
    user = JSON.parse(JSON.stringify(user));

    delete user.billing;
    delete user.hidden;
    delete user.teams;
    delete user.visible;

    if (user.hasOwnProperty('classifications')) {
      user.classifications = this.cleanClassifications(user.classifications);
    }

    return this.AppCMS.loadPluginData('user', { user: user }, ['update']);
  }

  updateUserGroup(userGroup: any) {
    return this.AppCMS.loadPluginData('customers', { group: userGroup }, ['user_groups', 'update']);
  }

}