<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" *ngIf="!!device">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!!view.introCard" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- Device information -->
    <div [hidden]="!!view.loading" *ngIf="!!device">

      <!-- Device name -->
      <ion-card>

        <ion-list lines="none">

          <!-- Photo + Name -->
          <ion-item>

            <ion-input type="text" [label]="'name'|translate" labelPlacement="stacked" [(ngModel)]="device.description"
              [placeholder]="'device_name'|translate"></ion-input>

          </ion-item>

        </ion-list>

      </ion-card>

      <!-- Setup -->
      <ion-card *ngIf="!!view.setup_steps && !!view.setup_steps.length">

        <!-- Setup steps -->
        <ion-list>

          <!-- Single setup step -->
          <ion-item *ngFor="let step of view.setup_steps">

            <!-- Step icon -->
            <ion-icon [name]="step.icon" slot="start"></ion-icon>

            <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="step.checked" [disabled]="step.disabled">
              <ion-label class="ion-text-wrap">
                <h3 [innerHTML]="step.label|translate"></h3>
                <p [innerHTML]="step.description|translate"></p>
              </ion-label>
            </ion-checkbox>

            <!-- Done checkmark -->
            <ion-icon name="checkmark-outline" [hidden]="!step.done" slot="end"></ion-icon>

          </ion-item>

        </ion-list>

      </ion-card>

      <!-- Appearance -->
      <ion-card>

        <ion-list>

          <!-- Scale factor -->
          <ion-item lines="none">

            <ion-icon name="remove" slot="start" color="primary" (click)="scaleFactorChanged(-1)"
              [class.disabled]="view.scale_factor === 1"></ion-icon>

            <ion-range [(ngModel)]="view.scale_factor" min="1" snaps="true" step="1" max="5"
              [label]="'scale_factor'|translate" (ionChange)="scaleFactorChanged()"></ion-range>

            <ion-icon name="add" slot="end" color="primary" (click)="scaleFactorChanged(1)"
              [class.disabled]="view.scale_factor === 5"></ion-icon>

          </ion-item>

        </ion-list>

      </ion-card>

      <!-- Security -->
      <ion-card *ngIf="!!device.ip || !!device.os || !!device.timestamp">

        <ion-list>

          <!-- IP -->
          <ion-item lines="none" *ngIf="!!device.ip">

            <ion-icon name="finger-print-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'ip'|translate"></h3>
              <p [innerHTML]="device.ip"></p>
            </ion-label>

          </ion-item>

          <!-- Operating system -->
          <ion-item lines="none" *ngIf="!!device.os">

            <ion-icon *ngIf="!!device.icon" [name]="device.icon" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'operating_system'|translate"></h3>
              <p [innerHTML]="('operating_system_' + device.os)|translate"></p>
            </ion-label>

          </ion-item>

          <!-- Date created -->
          <ion-item lines="none" *ngIf="!!device.timestamp">

            <ion-icon name="calendar-outline" slot="start"></ion-icon>

            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'first_login'|translate"></h3>
              <p [innerHTML]="device.timestamp"></p>
            </ion-label>

          </ion-item>

        </ion-list>

      </ion-card>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!device">
  <ion-toolbar class="container ion-no-border">

    <ion-grid>
      <ion-row>

        <ion-col [size]="6">

          <!-- Delete device -->
          <ion-button expand="block" color="primary" fill="solid" (click)="setup()"
            [disabled]="!device.description || !device.description.length">
            <ion-icon name="checkmark-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'setup'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>

        </ion-col>

        <ion-col [size]="6">

          <!-- Delete device -->
          <ion-button expand="block" color="danger" fill="clear" (click)="skip()">
            <ion-icon name="hand-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'skip'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>

        </ion-col>

      </ion-row>
    </ion-grid>

  </ion-toolbar>
</ion-footer>