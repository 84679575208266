import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
  ) {
    
  }

  get(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {

      if(config.query && config.query.length < 3) {
        delete config.query;
      }
      
      this.AppCMS.loadPluginData("pipeline", config, ["cities"], {}, blForceRefresh)
      .then((cities: city[]) => {
        resolve(cities);
      })
      .catch(reject);
    });
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.get(blForceRefresh, options);
  }

  pick(options: any = null) {
    return new Promise(async (resolve, reject) => {
      
      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.get(),
        labelKey: 'city',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        resolve(chooseResponse);
      })
      .catch(reject);
    });
  }

}
