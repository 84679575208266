import { Injectable } from '@angular/core';

// @xenova/transformers
//import { pipeline } from '@xenova/transformers';

// @mlc-ai/web-llm
//import * as webllm from "@mlc-ai/web-llm";
//import { CreateMLCEngine } from "@mlc-ai/web-llm";

import { createAction, props } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class AiLoaderService {

    modelName: string = "Llama-3.1-8B-Instruct-q4f32_1-MLC";

    async getEngine() {

        // Callback function to update model loading progress
        /*
        const initProgressCallback = (initProgress) => {
            console.log(initProgress);
        };

        const selectedModel = this.getSelectedModel();
        console.log('llm: selectedModel', selectedModel);

        const engine = await CreateMLCEngine(
            selectedModel,
            { initProgressCallback: initProgressCallback }, // engineConfig
        );

        return engine;
        */
    }

    getSelectedModel() {
        return this.modelName;
    }

    async load() {
        const engine: any = await this.getEngine();
        console.log('llm: engine', engine);

        return {
            engine: engine,
        };
    }

    setSelectedModel(modelName: string) {
        this.modelName = modelName;
        return this;
    }

}