import { Injectable } from '@angular/core';
import { pipelineAppConfig } from 'src/config/variables';

import { EventsService } from 'src/app/services/core/events.service';

import { createAction, props } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: pipelineAppConfig;

  constructor(
    private events: EventsService,
  ) {
  }

  filterDiscoverPostsByContentHostUrl() {
    let config: pipelineAppConfig = this.getConfig();
    return this.isWhitelabel() && !!this.getContentHostUrl() && !!config.filterDiscoverPostsByContentHostUrl;
  }

  filterHomePostsByContentHostUrl() {
    let config: pipelineAppConfig = this.getConfig();
    return this.isWhitelabel() && !!this.getContentHostUrl() && !!config.filterHomePostsByContentHostUrl;
  }

  filterLocalPostsByContentHostUrl() {
    let config: pipelineAppConfig = this.getConfig();
    return this.isWhitelabel() && !!this.getContentHostUrl() && !!config.filterLocalPostsByContentHostUrl;
  }

  filterPeopleByContentHostUrl() {
    let config: pipelineAppConfig = this.getConfig();
    return this.isWhitelabel() && !!this.getContentHostUrl() && !!config.filterPeopleByContentHostUrl;
  }

  filterSearchResultsByContentHostUrl() {
    let config: pipelineAppConfig = this.getConfig();
    return this.isWhitelabel() && !!this.getContentHostUrl() && !!config.filterSearchResultsByContentHostUrl;
  }

  filterUserByEmail() {
    let config: pipelineAppConfig = this.getConfig();
    return config.filterUserByEmail;
  }

  get(param: string) {
    return this.getConfigParam(param);
  }

  getAppId() {
    return this.getConfigParam('appId') || '';
  }

  getAppName() {
    return this.getConfigParam('appName') || '';
  }

  getAppPackageName() {
    return this.getConfigParam('appPackageName') || '';
  }

  getAppVersionId() {
    return this.getConfigParam('appVersionId') || '';
  }

  getAppVersionName() {
    return this.getConfigParam('appVersionName') || '';
  }

  getConfig() {
    const fromCache = (window.localStorage.getItem('appConfig') ? JSON.parse(window.localStorage.getItem('appConfig')) : null);

    if (!!fromCache && !this.config) {
      this.config = fromCache;
    }

    return this.config || pipelineAppConfig;
  }

  getConfigParam(key: string) {
    if (!this.config) {
      this.config = this.getConfig();
    }
    return this.config[key];
  }

  getContentHostUrl() {
    return this.getConfigParam('contentHostUrl') || '';
  }

  getContentHostUrlLike() {
    return !!this.getConfigParam('contentHostUrlLike');
  }

  getEnvironment(environment: string) {
    const environments = this.getEnvironments();
    return (environments && environments[environment] ? environments[environment] : {});
  }

  getEnvironments() {
    return this.getConfigParam('environments');
  }

  getEnvironmentUrl(environment: string, type: string = 'api') {
    const config: pipelineEnvironmentConfig = this.getEnvironment(type);
    return (config && config[environment] ? config[environment] : null);
  }

  getGlobalCreateItems() {
    return this.getConfigParam('globalCreateItems');
  }

  getHostUrl(environment: string = 'production') {
    return this.getEnvironmentUrl(environment, 'app');
  }

  getLink(link: string) {
    const links = this.getLinks();
    return (links && links[link] ? links[link] : null);
  }

  getLinks() {
    return this.getConfigParam('links');
  }

  getMaxPostAge() {
    const config: pipelineAppConfig = this.getConfig();
    return (config.maxPostAge || 365);
  }

  getOpenerURL() {
    const config: pipelineAppConfig = this.getConfig();
    return `${config.openerURL || 'https://open.getgenius.app/'}`;
  }

  getOrderBy(route: string) {
    const config: pipelineAppConfig = this.getConfig();
    return (config && config.orderBy && config.orderBy.hasOwnProperty(route) ? config.orderBy[route] : 'RAND()');
  }

  getPushApiKey() {
    const config: pipelineAppConfig = this.getConfig();
    return (config && config.pushApiKey ? config.pushApiKey : null);
  }

  getPushAppId() {
    const config: pipelineAppConfig = this.getConfig();
    return (config && config.pushAppId ? config.pushAppId : null);
  }

  getRoute(route: string) {
    const routes = this.getRoutes();
    route = (route || '').replace('tabs/', '');
    return (routes && routes[route] ? routes[route] : `/${route}`);
  }

  getRoutes() {
    const config: pipelineAppConfig = this.getConfig();
    return (config && config.routes ? config.routes : {});
  }

  getUserPostContentSettings() {
    const config: pipelineAppConfig = this.getConfig();
    return (config && config.userPostContentSettings ? config.userPostContentSettings : {});
  }

  init() {
    this.initWhitelabelConfiguratorEvents();
  }

  initWhitelabelConfiguratorEvents() {
    window.addEventListener("message", (event) => {

      if (event && event.data && event.data.colors) {
        this.events.publish('theme:colors:updated', event.data.colors);
      }

      if (event && event.data && event.data.config) {
        this.setConfig(event.data.config);
        this.events.publish('config:updated', this.config);
      }

    });
  }

  isApiURLConfigurable() {
    return !!this.getConfigParam('isApiURLConfigurable');
  }

  isWhitelabel() {
    return !!this.getConfigParam('isWhitelabel');
  }

  setConfig(appConfig: pipelineAppConfig) {
    this.config = appConfig;
    window.localStorage.setItem('appConfig', JSON.stringify(appConfig));
  }

  shouldShuffle(route: string) {
    let blShuffle: boolean = false;
    const orderBy: string = this.getOrderBy(route);

    switch (orderBy) {
      case 'auto':
        blShuffle = true;
        break;
      case 'RAND()':
        blShuffle = true;
        break;
    }

    return !!blShuffle;
  }

  showProfileUserGroup() {
    return !!this.getConfigParam('showProfileUserGroup');
  }

  showProfileUserGroupAsPrimaryLabel() {
    return !!this.getConfigParam('showProfileUserGroupAsPrimaryLabel');
  }

  useAvatars() {
    return !!this.getConfigParam('useAvatars');
  }

  useCategories() {
    return !!this.getConfigParam('useCategories');
  }

  useCategoriesOnDiscoverPage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useCategories() && !!config.useCategoriesOnDiscoverPage;
  }

  useCategoriesOnHomePage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useCategories() && !!config.useCategoriesOnHomePage;
  }

  useCategoriesOnLocalPage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useCategories() && !!config.useCategoriesOnLocalPage;
  }

  useDiscover() {
    return !!this.getConfigParam('useDiscover');
  }

  useHome() {
    return !!this.getConfigParam('useHome');
  }

  useInbox() {
    return !!this.getConfigParam('useInbox');
  }

  useInterests() {
    return !!this.getConfigParam('useInterests');
  }

  useLocal() {
    return !!this.getConfigParam('useLocal');
  }

  useShowroom() {
    return !!this.getConfigParam('useShowroom');
  }

  useSimplyLocalExtension() {
    return !!this.getConfigParam('useSimplyLocalExtension');
  }

  useStories() {
    return !!this.getConfigParam('useStories');
  }

  useStoriesOnDiscoverPage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useStories() && !!config.useStoriesOnDiscoverPage;
  }

  useStoriesOnHomePage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useStories() && !!config.useStoriesOnHomePage;
  }

  useStoriesOnLocalPage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useStories() && !!config.useStoriesOnLocalPage;
  }

  useStoriesOnPeoplePage() {
    const config: pipelineAppConfig = this.getConfig();
    return this.useStories() && !!config.useStoriesOnPeoplePage;
  }

  useWallet() {
    return !!this.getConfigParam('useWallet');
  }

  useWeclappExtension() {
    return !!this.getConfigParam('useWeclappExtension');
  }

}