<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title *ngIf="view.mode === 'edit'" [innerHTML]="'edit_target_group'|translate"></ion-title>
    <ion-title *ngIf="view.mode !== 'edit'" [innerHTML]="'create_target_group'|translate"></ion-title>

    <ion-buttons slot="end">
      <ion-button *ngIf="view.mode != 'edit'" (click)="create()" color="primary" shape="round" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>
      <ion-button *ngIf="view.mode == 'edit'" (click)="update()" color="primary" shape="round" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <ion-grid>

    <ion-row>

      <ion-col>
        <ion-card>

          <ion-card-header>
            <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
          </ion-card-header>

          <ion-list lines="none">
            <ion-item>
              <ion-label position="stacked" [innerHTML]="'target_group_label'|translate"></ion-label>
              <ion-input [(ngModel)]="targetGroup.label" type="text"
                [placeholder]="'target_group_label_placeholder'|translate"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked" [innerHTML]="'age'|translate"></ion-label>
              <ion-range dualKnobs="true" pin="true" [(ngModel)]="view.age" (ionChange)="onAgeChanged()"
                [min]="view.ageSelector.min" [max]="view.ageSelector.max">
                <ion-label slot="start" [innerHTML]="view.ageSelector.min"></ion-label>
                <ion-label slot="end" [innerHTML]="view.ageSelector.max"></ion-label>
              </ion-range>
            </ion-item>
            <ion-item>
              <ion-label position="stacked" [innerHTML]="'gender'|translate"></ion-label>
              <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="view.genders" (ionChange)="onGendersChanged()" multiple>
                <ion-select-option *ngFor="let gender of view.gendersData" [value]="gender"
                  [innerHTML]="('gender_' + gender)|translate"></ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>

        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title [innerHTML]="'interests'|translate"></ion-card-title>
          </ion-card-header>
          <ion-searchbar [(ngModel)]="view.interests" [placeholder]="'search'|translate"
            (ionChange)="onInterestsChanged()"></ion-searchbar>

          <ion-item button (click)="pickCategories()">
            <ion-label [innerHTML]="'categories'|translate"></ion-label>
            <ion-note [innerHTML]="'select'|translate" slot="end"></ion-note>
          </ion-item>

        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title [innerHTML]="'region'|translate"></ion-card-title>
          </ion-card-header>
          <ion-list lines="none">

            <ion-item>
              <ion-label position="stacked" [innerHTML]="'countries'|translate"></ion-label>
              <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="view.countries" (ionChange)="onCountriesChanged()" multiple>
                <ion-select-option *ngFor="let country of view.countriesData" [value]="country.uid"
                  [innerHTML]="country.name|translate"></ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item button (click)="pickCities()">
              <ion-label [innerHTML]="'cities'|translate" position="stacked"></ion-label>
              <ion-note [innerHTML]="'select'|translate" slot="end"></ion-note>
            </ion-item>

            <pipeline-languages-select [(key)]="view.languages" [options]="languageOptions" (changed)="onLanguagesChanged()"></pipeline-languages-select>

          </ion-list>
        </ion-card>
        
        <ion-card>
          <ion-card-header>
            <ion-card-title [innerHTML]="'platforms'|translate"></ion-card-title>
          </ion-card-header>
          <ion-list>
            <ion-item>
              <ion-label [innerHTML]="'device'|translate"></ion-label>
              <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                (ionChange)="platformDevicesChanged()" [(ngModel)]="view.platform.devices" multiple>
                <ion-select-option *ngFor="let device of view.platform.availableDevices"
                  [innerHTML]="('device_' + device)|translate" [value]="device"></ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label [innerHTML]="'apps'|translate"></ion-label>
              <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                (ionChange)="platformAppsChanged()" [(ngModel)]="view.platform.apps" multiple>
                <ion-select-option *ngFor="let app of view.platform.availableApps" [innerHTML]="app.name"
                  [value]="app.uid"></ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-card>
      </ion-col>

      <ion-col>
        <div id="targetGroupMap" class="map"></div>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>