<ion-card class="sidebar-accordion-card">

    <!-- Header -->
    <ion-card-header>

        <!-- Title -->
        <ion-card-title [innerHTML]="'folders'|translate"></ion-card-title>

        <!-- Create new folder -->
        <ion-button [hidden]="view.createFolderMode" *ngIf="(view.mode !== 'pick')" color="primary" fill="clear"
            (click)="createFolder()">
            <ion-icon name="add-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'new'|translate"></ion-label>
        </ion-button>

        <!-- Toggle -->
        <ion-button *ngIf="!!cards && !!cards.folders" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
            fill="clear">
            <ion-icon [name]="!!cards && cards.folders.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Create new folder mode-->
    <div [hidden]="!cards || !cards.folders || !cards.folders.open || !view.createFolderMode">

        <ion-list>

            <!-- Folder name -->
            <ion-item lines="none">
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_folder_dialog_header'|translate" position="floating"></ion-label>
                <ion-input type="text" [(ngModel)]="view.folderName"></ion-input>
            </ion-item>

            <!-- Parent folder -->
            <ion-item lines="none" *ngIf="!!view.folders && !!view.folders.length">
                <ion-icon name="folder-outline" slot="start"></ion-icon>
                <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                    [label]="'parent'|translate" [(ngModel)]="view.folderParent">
                    <ion-select-option [value]="0" [innerHTML]="'none'|translate"></ion-select-option>
                    <ion-select-option *ngFor="let folder of view.folders" [value]="folder.uid"
                        [hidden]="!!folder.parent" [innerHTML]="folder.title"></ion-select-option>
                </ion-select>
            </ion-item>

        </ion-list>

        <!-- Actions -->
        <ion-grid>
            <ion-row>

                <ion-col size="6">
                    <ion-button expand="block" color="primary" fill="clear" (click)="cancelFolderMode()">
                        <ion-icon name="close-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'cancel'|translate"></ion-label>
                    </ion-button>
                </ion-col>

                <ion-col size="6">
                    <ion-button expand="block" color="primary" (click)="saveFolder()"
                        [disabled]="!view.folderName || !view.folderName.length">
                        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'create'|translate"></ion-label>
                    </ion-button>
                </ion-col>

            </ion-row>
        </ion-grid>

    </div>

    <!-- Folders list -->
    <ion-list class="folders-list"
        [hidden]="(!cards || !cards.folders || !cards.folders.open) || !!view.createFolderMode"
        [class.expanded]="view.expandedList === 'folders'">

        <!-- Breadcrumbs -->
        <pipeline-breadcrumbs [(view)]="view"></pipeline-breadcrumbs>

        <!-- View all folders -->
        <ion-item button [disabled]="!!view.loading" class="btn-item" lines="none" (click)="allFolders()"
            [class.active]="!view.currentFolder">
            <ion-icon [name]="(!view.currentFolder ? 'folder-open-outline' : 'folder-outline')" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
                <p [innerHTML]="'all_folders'|translate" class="ion-text-wrap"></p>
            </ion-label>
        </ion-item>

        <!-- Loading folders -->
        <ion-spinner [hidden]="(!!view.folders && !!view.folders.length) || !view.loadingFolders"></ion-spinner>

        <!-- No folders -->
        <pipeline-no-entries-card
            *ngIf="(!view.folders || (view.folders && !view.folders.length)) && !view.loadingFolders"></pipeline-no-entries-card>

        <!-- Folders list -->
        <div [dragula]="view.isDesktop ? 'FOLDERS_LIST' : false" [(dragulaModel)]="view.folders"
            [hidden]="!!view.loadingFolders" *ngIf="!!view.folders && !!view.folders.length">

            <!-- Single folder (+ children) -->
            <ion-item-sliding *ngFor="let folder of view.folders; let i = index; trackBy:trackItems"
                [class.active]="folder.active" [attr.data-location]="location" [attr.data-type]="'folder'"
                [attr.data-uid]="folder.uid">

                <!-- Main folder -->
                <ion-item button [disabled]="!!view.loading || !!folder.loading" class="btn-item ion-text-wrap"
                    [hidden]="!!folder.parent" [class.active]="folder.active" [attr.data-location]="location"
                    [attr.data-type]="'folder'" [attr.data-uid]="folder.uid">

                    <!-- Reorder folder -->
                    <ion-button slot="start" class="drag-handle folders-drag-handler" fill="clear" icon-only
                        color="dark">
                        <ion-icon name="reorder-two-outline"></ion-icon>
                    </ion-button>

                    <!-- Folder icon -->
                    <ion-icon (click)="openFolder(folder)"
                        [name]="folder.icon || (folder.active ? 'folder-open-outline' : 'folder-outline')"
                        slot="start"></ion-icon>

                    <!-- Parent folder title -->
                    <ion-label (click)="openFolder(folder)" class="ion-text-wrap">
                        <p [innerHTML]="folder.title" class="ion-text-wrap"></p>
                    </ion-label>

                    <!-- Folder is loading -->
                    <ion-spinner slot="end" [hidden]="!!folder.uid && !folder.loading"></ion-spinner>

                    <!-- Folder settings -->
                    <ion-button slot="end" color="dark" fill="clear" [hidden]="!!folder.loading"
                        *ngIf="!!folder.uid && (folder.uid !== -1) && (view.mode !== 'pick')"
                        (click)="folderSettings(folder)">
                        <ion-icon name="settings-outline"></ion-icon>
                    </ion-button>

                    <!-- Delete parent folder -->
                    <ion-button slot="end" color="danger" fill="clear" [hidden]="!!folder.loading"
                        *ngIf="!!folder.uid && (folder.uid !== -1) && (view.mode !== 'pick')"
                        (click)="deleteFolder(folder)">
                        <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>

                    <!-- Create folder idea -->
                    <ion-button slot="end" color="dark" fill="clear" [hidden]="!!folder.loading"
                        *ngIf="(!folder.uid || (folder.uid === -1)) && (view.mode !== 'pick')"
                        (click)="createFolderIdea(folder, i)">
                        <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>

                </ion-item>

                <!-- Folder children (in tree view) -->
                <ion-list class="children" *ngIf="!!folder.active">

                    <!-- Single child folder -->
                    <ion-item *ngFor="let child of view.folders; trackBy:trackItems"
                        [disabled]="!!view.loading || !!child.loading" [hidden]="child.parent !== folder.uid" button
                        class="btn-item ion-text-wrap" [class.active]="child.active" [attr.data-location]="location"
                        [attr.data-type]="'folder'" [attr.data-uid]="child.uid">

                        <!-- Reorder child folder -->
                        <ion-button slot="start" class="drag-handle folders-drag-handler" fill="clear" icon-only
                            color="dark">
                            <ion-icon name="reorder-two-outline"></ion-icon>
                        </ion-button>

                        <!-- Child folder icon -->
                        <ion-icon (click)="openFolder(child)"
                            [name]="child.icon || (child.active ? 'folder-open-outline' : 'folder-outline')"
                            slot="start"></ion-icon>

                        <!-- Child folder title -->
                        <ion-label (click)="openFolder(child)" class="ion-text-wrap">
                            <p [innerHTML]="child.title" class="ion-text-wrap"></p>
                        </ion-label>

                        <!-- Sub-folder is loading -->
                        <ion-spinner slot="end" [hidden]="!!child.uid && !child.loading"></ion-spinner>

                        <!-- Child folder setings -->
                        <ion-button slot="end" color="dark" fill="clear" *ngIf="!!child.uid && (view.mode !== 'pick')"
                            (click)="folderSettings(child)">
                            <ion-icon name="settings-outline"></ion-icon>
                        </ion-button>

                        <!-- Delete child folder -->
                        <ion-button slot="end" color="danger" fill="clear" *ngIf="!!child.uid && (view.mode !== 'pick')"
                            (click)="deleteFolder(child)">
                            <ion-icon name="trash-outline"></ion-icon>
                        </ion-button>

                    </ion-item>
                </ion-list>

            </ion-item-sliding>

        </div>

    </ion-list>

    <!-- Chevron (deprecated?)-->
    <ion-fab [hidden]="!cards || !cards.folders || !cards.folders.open">
        <ion-fab-button size="small" (click)="expandList('folders')">
            <ion-icon name="chevron-down"></ion-icon>
        </ion-fab-button>
    </ion-fab>

</ion-card>