import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
//import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TargetgroupsService } from "src/app/services/target-groups/target-groups.service";

import { CreateTargetGroupPage } from 'src/app/pages/extensions/target_groups/create-target-group/create-target-group.page';
import { TargetGroupsPage } from 'src/app/pages/extensions/target_groups/target-groups/target-groups.page';

@Component({
  selector: 'pipeline-target-groups-picker',
  templateUrl: './target-groups-picker.component.html',
  styleUrls: ['./target-groups-picker.component.scss']
})
export class TargetGroupsPickerComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() cards: any;
  @Input() disabled: boolean;
  @Input() view: any;
  
  fallbackImg: string = './assets/img/fallback.webp';

  targetGroups: any = {};

  typeIcons: any = {
    media: 'images-outline',
    text: 'text-outline',
  };

  typeStates: any = {};

  constructor(
    public ai: AiToolsService,
    private events: EventsService,
    private modalService: ModalService,
    //private navCtrl: NavController,
    private projects: ProjectsService,
    private targetGroupsService: TargetgroupsService,
  ) {
    this.view = this.view || {};
    this.view.style = this.view.style || {};    
  }

  addTargetGroup() {
    this.targetGroupsService.pick({
      multiple: true,
    })
    .then((response: any) => {
      console.log('response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async createTargetGroup() {
    
    const modal: any = await this.modalService.create({
      component: CreateTargetGroupPage,
    });

    modal.onWillDismiss().then((response: any) => {
      console.log('target-groups: dismiss', response);
    });
    
    this.modalService.present(modal);

  }

  configureStyle(type: any) {
    console.log('configure style', type);
  }

  doRefresh() {
    this.loadTargetGroups(true);
  }
  
  async edit(style: any) {
    console.log('edit', style);
  }

  initEvents() {
    this.view.events = {};
    
    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

  }

  initViewVars() {
    this.view.aiStyleTypes = this.view.aiStyleTypes || ['media', 'text'];
    this.view.style = this.view.style || {};
  }

  async loadTargetGroups(blForceRefresh: boolean = false) {
    this.view.project = await this.projects.getCurrent();

    if(!this.view.project || !this.view.project.uid) {
      return false;
    }
    
    return new Promise((resolve, reject) => {
      this.targetGroupsService.getAll(blForceRefresh)
      .then((targetGroups: any[]) => {
        this.view.target_groups = targetGroups;
        resolve(targetGroups);
      })
      .catch(reject);
    })
  }
  
  ngAfterContentInit() {
    this.initViewVars();

    /*
    this.loadTargetGroups()
    .then(() => {

      // set first style to current
      if(!!this.styles && !!this.styles[0] && !!this.styles[0].uid) {
        this.view.style = this.styles[0];
      }
      
    })
    .catch((error: any) => {
      console.warn('loading styles failed', error);
    });
    */
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
  }

  styleChanged(type: any) {
    console.log('styleChanged', type);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }
  
  toggleTypeState(type: any) {
    this.typeStates[type] = !this.typeStates[type];
  }

  async viewAll() {
    //this.events.publish('menu:load:appPages', {key: 'styles_admin'});
    //this.navCtrl.navigateForward('/target-groups');

    const modal: any = await this.modalService.create({
      component: TargetGroupsPage,
    });

    modal.onWillDismiss().then((response: any) => {
      console.log('target-groups: dismiss', response);
    });
    
    this.modalService.present(modal);

  }

}