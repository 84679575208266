import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { GetgeniusService } from '../getgenius/getgenius.service';

import { createAction, props } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AiBridgeService {

  constructor(
    private AppCMS: AppcmsService,
    private getgenius: GetgeniusService,
  ) {

  }

  execute(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any = null, params: any = {}) {
    return new Promise((resolve, reject) => {

      if (!!config) {
        item.config = config;
      }

      // register ai execution job on the server-side
      this.getgenius.registerAction({
        config: config,
        item: item,
        params: params,
      })
        .then((response: any) => {
          // after registering the job, execute it
          this.AppCMS.loadPluginData('pipeline', Object.assign((params || {}), {
            item: item,
          }), ['ai', 'execute'], {}, blForceRefresh)
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

}