import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthService } from './services/core/auth.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/core/dashboard/dashboard.module').then(m => m.CreatorsDashboardPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/assistants',
    loadChildren: () => import('./pages/core/ai/ai-assistants/ai-assistants.module').then(m => m.AiAssistantsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/assistant/:assistantId',
    loadChildren: () => import('./pages/getgenius/dani/dani.module').then(m => m.DaniPageModule),
  },
  {
    path: 'ai/assistant/:assistantId/settings',
    loadChildren: () => import('./pages/core/ai/ai-assistant/ai-assistant.module').then(m => m.AiAssistantPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/browser',
    loadChildren: () => import('./pages/core/ai/ai-browser/ai-browser.module').then(m => m.AiBrowserPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/coder',
    loadChildren: () => import('./pages/core/ai/ai-coder/ai-coder.module').then(m => m.AiCoderPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/coder/:codeProjectId',
    loadChildren: () => import('./pages/core/ai/ai-coder/ai-coder.module').then(m => m.AiCoderPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/codes',
    loadChildren: () => import('./pages/core/ai/ai-codes/ai-codes.module').then(m => m.AiCodesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/models',
    loadChildren: () => import('./pages/core/ai/ai-models/ai-models.module').then(m => m.AiModelsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/model',
    loadChildren: () => import('./pages/core/ai/ai-model/ai-model.module').then(m => m.AiModelPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/search',
    loadChildren: () => import('./pages/core/ai/ai-search/ai-search.module').then(m => m.AiSearchPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/store',
    loadChildren: () => import('./pages/core/ai/ai-store/ai-store.module').then(m => m.AiStorePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/tasks',
    loadChildren: () => import('./pages/core/ai/ai-tasks/ai-tasks.module').then(m => m.AiTasksPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/task/benchmark',
    loadChildren: () => import('./pages/core/ai/ai-task-benchmark/ai-task-benchmark.module').then(m => m.AiTaskBenchmarkPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/task/cron',
    loadChildren: () => import('./pages/core/ai/ai-task-cron-settings/ai-task-cron-settings.module').then(m => m.AiTaskCronSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/task/execute/settings',
    loadChildren: () => import('./pages/core/ai/ai-task-execution-settings/ai-task-execution-settings.module').then(m => m.AiTaskExecutionSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/tools',
    loadChildren: () => import('./pages/core/ai/ai-tools/ai-tools.module').then(m => m.AiToolsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/training',
    loadChildren: () => import('./pages/core/ai/ai-training/ai-training.module').then(m => m.AiTrainingPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/training/knowledgebase',
    loadChildren: () => import('./pages/core/ai/ai-knowledgebase/ai-knowledgebase.module').then(m => m.AiKnowledgebasePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/training/knowledgebase/:itemId',
    loadChildren: () => import('./pages/core/ai/ai-knowledgebase-entry/ai-knowledgebase-entry.module').then(m => m.AiKnowledgebaseEntryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/training/log',
    loadChildren: () => import('./pages/core/ai/ai-training/ai-training.module').then(m => m.AiTrainingPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/text-to-audio',
    loadChildren: () => import('./pages/core/ai/ai-text-to-audio/ai-text-to-audio.module').then(m => m.AiTextToAudioPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/text-to-speech',
    loadChildren: () => import('./pages/core/ai/ai-tts/ai-tts.module').then(m => m.AiTtsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/shortcode',
    loadChildren: () => import('./pages/core/ai/ai-shortcode/ai-shortcode.module').then(m => m.AiShortcodePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/automation/:taskId',
    loadChildren: () => import('./pages/core/ai/ai-task/ai-task.module').then(m => m.AiTaskPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/task',
    loadChildren: () => import('./pages/core/ai/ai-task/ai-task.module').then(m => m.AiTaskPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/ideas',
    loadChildren: () => import('./pages/core/ai/ai-ideas/ai-ideas.module').then(m => m.AiIdeasPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/plans',
    loadChildren: () => import('./pages/core/ai/ai-plans/ai-plans.module').then(m => m.AiPlansPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'api/explorer',
    loadChildren: () => import('./pages/core/api/explorer/api-explorer.module').then(m => m.ApiExplorerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'api/explorer/:integrationName',
    loadChildren: () => import('./pages/core/api/explorer/api-explorer.module').then(m => m.ApiExplorerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./pages/core/ai/ai-plans/ai-plans.module').then(m => m.AiPlansPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'campaigns/:planId',
    loadChildren: () => import('./pages/core/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'campaigns/:planId/edit',
    loadChildren: () => import('./pages/core/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'campaigns/:planId/preview',
    loadChildren: () => import('./pages/core/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'campaigns/:planId/review',
    loadChildren: () => import('./pages/core/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/planner',
    loadChildren: () => import('./pages/core/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/url-lookup',
    loadChildren: () => import('./pages/core/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'links',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'link/lookup',
    loadChildren: () => import('./pages/core/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'link/:lookupIndent',
    loadChildren: () => import('./pages/core/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/video-creator',
    loadChildren: () => import('./pages/core/ai/ai-video-creator/ai-video-creator.module').then(m => m.AiVideoCreatorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/video-creator/create',
    loadChildren: () => import('./pages/core/ai/ai-video-creator/ai-video-creator.module').then(m => m.AiVideoCreatorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ai/video-creator/list',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/core/account/account/account.module').then(m => m.AccountPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'account/devices',
    loadChildren: () => import('./pages/core/devices/devices/devices.module').then(m => m.DevicesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'account/devices/setup',
    loadChildren: () => import('./pages/core/devices/device-setup/device-setup.module').then(m => m.DeviceSetupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'account/devices/:token',
    loadChildren: () => import('./pages/core/devices/device/device.module').then(m => m.DevicePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'account/usage',
    loadChildren: () => import('./pages/core/account/usage/usage.module').then(m => m.UsagePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'abonnements',
    loadChildren: () => import('./pages/core/account/abonnements/abonnements/abonnements.module').then(m => m.AbonnementsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'data/duplicate',
    loadChildren: () => import('./pages/core/data/data-duplicator/data-duplicator.module').then(m => m.DataDuplicatorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'data/editor',
    loadChildren: () => import('./pages/core/data/data-editor/data-editor.module').then(m => m.DataEditorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'data/export',
    loadChildren: () => import('./pages/core/data/data-exporter/data-exporter.module').then(m => m.DataExporterPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'data/importer',
    loadChildren: () => import('./pages/core/data/data-importer/data-importer.module').then(m => m.DataImporterPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/core/account/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'generic/item',
    loadChildren: () => import('./pages/core/generic/generic-item/generic-item.module').then(m => m.GenericItemPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'generic/item',
    loadChildren: () => import('./pages/core/generic/generic-list/generic-list.module').then(m => m.GenericListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/core/account/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/core/account/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/core/account/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'post',
    loadChildren: () => import('./pages/core/post/post/post.module').then(m => m.PostPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/extensions/shop/detail/detail.module').then(m => m.DetailPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'dynamic/:pageId',
    loadChildren: () => import('./pages/extensions/pages/dynamic/dynamic.module').then(m => m.DynamicPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'basket',
    loadChildren: () => import('./pages/extensions/shop/basket/basket.module').then(m => m.BasketPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/extensions/shop/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'configure-product',
    loadChildren: () => import('./pages/extensions/shop/configure-product/configure-product.module').then(m => m.ConfigureProductPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'coupon',
    loadChildren: () => import('./pages/extensions/coupons/coupon/coupon.module').then(m => m.CouponPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/extensions/shop/payment/payment.module').then(m => m.PaymentPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/extensions/contacts/contacts.module').then(m => m.ContactsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'customers-admin',
    loadChildren: () => import('./pages/core/customers/customers-admin/customers-admin.module').then(m => m.CustomersAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'customer',
    loadChildren: () => import('./pages/core/customers/customer/customer.module').then(m => m.CustomerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'stores-admin',
    loadChildren: () => import('./pages/extensions/stores/stores-admin/stores-admin.module').then(m => m.StoresAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/extensions/stores/store/store.module').then(m => m.StorePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'regional-admin',
    loadChildren: () => import('./pages/core/regions/regional-admin/regional-admin.module').then(m => m.RegionalAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'regional-create-source',
    loadChildren: () => import('./pages/core/regions/regional-create-source/regional-create-source.module').then(m => m.RegionalCreateSourcePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/core/account/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'rate-post',
    loadChildren: () => import('./pages/core/ratings/rate-post/rate-post.module').then(m => m.RatePostPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'create-source',
    loadChildren: () => import('./pages/core/sources/create-source/create-source.module').then(m => m.CreateSourcePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/core/account/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/core/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'experiments',
    loadChildren: () => import('./pages/core/account/experiments/experiments.module').then(m => m.ExperimentsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'appearance',
    loadChildren: () => import('./pages/core/account/appearance/appearance.module').then(m => m.AppearancePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ads',
    loadChildren: () => import('./pages/core/ads/ads-admin/ads-admin.module').then(m => m.AdsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ads/create',
    loadChildren: () => import('./pages/core/ads/create-ad/create-ad.module').then(m => m.CreateAdPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'telemetry',
    loadChildren: () => import('./pages/core/account/telemetry/telemetry.module').then(m => m.TelemetryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'ratings-admin',
    loadChildren: () => import('./pages/core/ratings/ratings-admin/ratings-admin.module').then(m => m.RatingsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'view/:slug',
    loadChildren: () => import('./pages/core/view/view.module').then(m => m.ViewPageModule),
  },
  {
    path: 'card-options',
    loadChildren: () => import('./pages/core/card-options/card-options.module').then(m => m.CardOptionsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/core/statistics/creator-statistics/creator-statistics.module').then(m => m.CreatorStatisticsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'statistics/social-media',
    loadChildren: () => import('./pages/core/statistics/social-media-statistics/social-media-statistics.module').then(m => m.SocialMediaStatisticsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'statistics/trends',
    loadChildren: () => import('./pages/core/statistics/trends-statistics/trends-statistics.module').then(m => m.TrendsStatisticsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'translation',
    loadChildren: () => import('./pages/core/translations/translation/translation.module').then(m => m.TranslationPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'translations',
    loadChildren: () => import('./pages/core/translations/translations/translations.module').then(m => m.TranslationsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'post-information',
    loadChildren: () => import('./pages/core/post/post-information/post-information.module').then(m => m.PostInformationPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'connect-account-with-siwa',
    loadChildren: () => import('./pages/core/connect/connect-account-with-siwa/connect-account-with-siwa.module').then(m => m.ConnectAccountWithSiwaPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'cpm-admin',
    loadChildren: () => import('./pages/core/ads/cpm/cpm-admin/cpm-admin.module').then(m => m.CpmAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'create-cpm-entry',
    loadChildren: () => import('./pages/core/ads/cpm/create-cpm-entry/create-cpm-entry.module').then(m => m.CreateCpmEntryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'create-cpm-main-entry',
    loadChildren: () => import('./pages/core/ads/cpm/create-cpm-main-entry/create-cpm-main-entry.module').then(m => m.CreateCpmMainEntryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'cpm-add-popup',
    loadChildren: () => import('./pages/core/ads/cpm/cpm-add-popup/cpm-add-popup.module').then(m => m.CpmAddPopupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-page/:postId',
    loadChildren: () => import('./pages/extensions/pages/edit-page/edit-page.module').then(m => m.EditPagePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-post/:postId',
    loadChildren: () => import('./pages/core/post/edit-post/edit-post.module').then(m => m.EditPostPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'post/new',
    loadChildren: () => import('./pages/core/post/edit-post/edit-post.module').then(m => m.EditPostPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'post/:postId',
    loadChildren: () => import('./pages/core/post/post/post.module').then(m => m.PostPageModule),
  },
  {
    path: 'edit-post-translations-modal',
    loadChildren: () => import('./pages/core/post/edit-post-translations-modal/edit-post-translations-modal.module').then(m => m.EditPostTranslationsModalPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'add-region',
    loadChildren: () => import('./pages/core/regions/add-region/add-region.module').then(m => m.AddRegionPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'blog-admin-employee-motivation',
    loadChildren: () => import('./pages/extensions/blog-admin/blog-admin-employee-motivation/blog-admin-employee-motivation.module').then(m => m.BlogAdminEmployeeMotivationPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-source',
    loadChildren: () => import('./pages/core/sources/edit-source/edit-source.module').then(m => m.EditSourcePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/core/cookies/cookies.module').then(m => m.CookiesPageModule),
  },
  {
    path: 'edit-post-settings',
    loadChildren: () => import('./pages/core/post/edit-post-settings/edit-post-settings.module').then(m => m.EditPostSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'region-setup',
    loadChildren: () => import('./pages/core/regions/region-setup/region-setup.module').then(m => m.RegionSetupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'choose-avatar',
    loadChildren: () => import('./pages/core/chooser/choose-avatar/choose-avatar.module').then(m => m.ChooseAvatarPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-shop-source',
    loadChildren: () => import('./pages/extensions/shop/edit-shop-source/edit-shop-source.module').then(m => m.EditShopSourcePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'choose-cover',
    loadChildren: () => import('./pages/core/chooser/choose-cover/choose-cover.module').then(m => m.ChooseCoverPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/core/modals/modal/modal.module').then(m => m.ModalPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'sharing-modal',
    loadChildren: () => import('./pages/core/modals/sharing-modal/sharing-modal.module').then(m => m.SharingModalPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'hub',
    loadChildren: () => import('./pages/core/post/posts-admin/posts-admin.module').then(m => m.PostsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'hub/settings',
    loadChildren: () => import('./pages/core/post/posts-admin-settings/posts-admin-settings.module').then(m => m.PostsAdminSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'hub/:segmentName',
    loadChildren: () => import('./pages/core/post/posts-admin/posts-admin.module').then(m => m.PostsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'web-uploader',
    loadChildren: () => import('./pages/core/web-uploader/web-uploader.module').then(m => m.WebUploaderPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'sources/blogs',
    loadChildren: () => import('./pages/extensions/blog-admin/creator-blogs/creator-blogs.module').then(m => m.CreatorBlogsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'sources/shops',
    loadChildren: () => import('./pages/extensions/shop/creator-shops/creator-shops.module').then(m => m.CreatorShopsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'connect/shop',
    loadChildren: () => import('./pages/core/connect/connect-shop/connect-shop.module').then(m => m.ConnectShopPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'connect/blog',
    loadChildren: () => import('./pages/core/connect/connect-blog/connect-blog.module').then(m => m.ConnectBlogPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'target-groups',
    loadChildren: () => import('./pages/extensions/target_groups/target-groups/target-groups.module').then(m => m.TargetGroupsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'target-groups/create',
    loadChildren: () => import('./pages/extensions/target_groups/create-target-group/create-target-group.module').then(m => m.CreateTargetGroupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'choose-action-sheet',
    loadChildren: () => import('./pages/core/chooser/choose-action-sheet/choose-action-sheet.module').then(m => m.ChooseActionSheetPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'icon-picker',
    loadChildren: () => import('./pages/core/chooser/icon-picker/icon-picker.module').then(m => m.IconPickerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/core/account/security/security.module').then(m => m.SecurityPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/core/account/change-email/change-email.module').then(m => m.ChangeEmailPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/core/chat/inbox/inbox.module').then(m => m.InboxPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'messenger',
    loadChildren: () => import('./pages/core/chat/messenger/messenger.module').then(m => m.MessengerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/core/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/core/connect/connect/connect.module').then(m => m.ConnectPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'creator-requests',
    loadChildren: () => import('./pages/extensions/creators/creator-requests/creator-requests.module').then(m => m.CreatorRequestsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'social-media-images-preview',
    loadChildren: () => import('./pages/core/preview/social-media-images-preview/social-media-images-preview.module').then(m => m.SocialMediaImagesPreviewPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-shop/:shopId',
    loadChildren: () => import('./pages/extensions/shop/edit-shop/edit-shop.module').then(m => m.EditShopPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/core/dashboard/dashboard.module').then(m => m.CreatorsDashboardPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'shop/categories',
    loadChildren: () => import('./pages/extensions/shop/shop-categories/shop-categories.module').then(m => m.ShopCategoriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'shop/category/:categoryId',
    loadChildren: () => import('./pages/extensions/shop/shop-category/shop-category.module').then(m => m.ShopCategoryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'shop/category/:categoryId/products',
    loadChildren: () => import('./pages/extensions/shop/shop-products/shop-products.module').then(m => m.ShopProductsPageModule)
  },
  {
    path: 'shop/products',
    loadChildren: () => import('./pages/extensions/shop/shop-products/shop-products.module').then(m => m.ShopProductsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'shop/products/:productId',
    loadChildren: () => import('./pages/extensions/shop/shop-product/shop-product.module').then(m => m.ShopProductPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'newsletters',
    loadChildren: () => import('./pages/extensions/newsletters/newsletters/newsletters.module').then(m => m.NewslettersPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'newsletters/create',
    loadChildren: () => import('./pages/extensions/newsletters/newsletter/newsletter.module').then(m => m.NewsletterPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'newsletters/:uid',
    loadChildren: () => import('./pages/extensions/newsletters/newsletter/newsletter.module').then(m => m.NewsletterPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/extensions/shop/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'post-reader-appearance-popup',
    loadChildren: () => import('./pages/core/post/post-reader-appearance-popup/post-reader-appearance-popup.module').then(m => m.PostReaderAppearancePopupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'creator/websites/edit/:websiteId',
    loadChildren: () => import('./pages/extensions/websites/edit-website/edit-website.module').then(m => m.EditWebsitePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'creator/websites',
    loadChildren: () => import('./pages/extensions/websites/creator-websites/creator-websites.module').then(m => m.CreatorWebsitesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'creator/website/create',
    loadChildren: () => import('./pages/extensions/websites/create-website/create-website.module').then(m => m.CreateWebsitePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'preview-post',
    loadChildren: () => import('./pages/core/preview/preview-post/preview-post.module').then(m => m.PreviewPostPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'preview-ad',
    loadChildren: () => import('./pages/core/preview/preview-ad/preview-ad.module').then(m => m.PreviewAdPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'preview-social-sharing-image',
    loadChildren: () => import('./pages/core/preview/preview-social-sharing-image/preview-social-sharing-image.module').then(m => m.PreviewSocialSharingImagePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'preview-social-sharing-image-filters',
    loadChildren: () => import('./pages/core/preview/preview-social-sharing-image-filters/preview-social-sharing-image-filters.module').then(m => m.PreviewSocialSharingImageFiltersPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'marketplace/dashboard',
    loadChildren: () => import('./pages/extensions/marketplace/creators-marketplace/creators-marketplace.module').then(m => m.CreatorsMarketplacePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'marketplace/search',
    loadChildren: () => import('./pages/extensions/marketplace/creators-marketplace-search/creators-marketplace-search.module').then(m => m.CreatorsMarketplaceSearchPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'marketplace/messages',
    loadChildren: () => import('./pages/core/chat/inbox/inbox.module').then(m => m.InboxPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'change-account',
    loadChildren: () => import('./pages/core/account/change-account/change-account.module').then(m => m.ChangeAccountPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'order-by-modal',
    loadChildren: () => import('./pages/core/modals/order-by-modal/order-by-modal.module').then(m => m.OrderByModalPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'confirm-modal',
    loadChildren: () => import('./pages/core/modals/confirm-modal/confirm-modal.module').then(m => m.ConfirmModalPageModule),
  },
  {
    path: 'whitelabel-apps',
    loadChildren: () => import('./pages/core/apps/whitelabel-apps/whitelabel-apps.module').then(m => m.WhitelabelAppsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'apps',
    loadChildren: () => import('./pages/core/apps/whitelabel-apps/whitelabel-apps.module').then(m => m.WhitelabelAppsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'marketplace-entry',
    loadChildren: () => import('./pages/extensions/marketplace/marketplace-entry/marketplace-entry.module').then(m => m.MarketplaceEntryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'push-notifications',
    loadChildren: () => import('./pages/extensions/push-notifications/push-notifications/push-notifications.module').then(m => m.PushNotificationsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'push-notification',
    loadChildren: () => import('./pages/extensions/push-notifications/push-notification/push-notification.module').then(m => m.PushNotificationPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'whitelabel-app',
    loadChildren: () => import('./pages/core/apps/whitelabel-app/whitelabel-app.module').then(m => m.WhitelabelAppPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'blog/categories',
    loadChildren: () => import('./pages/extensions/blog-admin/blog-categories/blog-categories.module').then(m => m.BlogCategoriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'blog/category/:categoryId',
    loadChildren: () => import('./pages/extensions/blog-admin/blog-category/blog-category.module').then(m => m.BlogCategoryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'blog/authors',
    loadChildren: () => import('./pages/extensions/blog-admin/blog-authors/blog-authors.module').then(m => m.BlogAuthorsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'blog/author/:authorId',
    loadChildren: () => import('./pages/extensions/blog-admin/blog-author/blog-author.module').then(m => m.BlogAuthorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'analytics-admin',
    loadChildren: () => import('./pages/core/statistics/analytics/analytics-admin/analytics-admin.module').then(m => m.AnalyticsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'analytics-entry',
    loadChildren: () => import('./pages/core/statistics/analytics/analytics-entry/analytics-entry.module').then(m => m.AnalyticsEntryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'feeds',
    loadChildren: () => import('./pages/extensions/feeds/feeds/feeds.module').then(m => m.FeedsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'feed/:feedId',
    loadChildren: () => import('./pages/extensions/feeds/feed/feed.module').then(m => m.FeedPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/core/account/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'whitelabel-app-popup-device',
    loadChildren: () => import('./pages/core/apps/whitelabel-app-popup-device/whitelabel-app-popup-device.module').then(m => m.WhitelabelAppPopupDevicePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'monetization',
    loadChildren: () => import('./pages/extensions/monetization/monetization/monetization.module').then(m => m.MonetizationPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/extensions/groups/groups/groups.module').then(m => m.GroupsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'surveys',
    loadChildren: () => import('./pages/extensions/surveys/surveys/surveys.module').then(m => m.SurveysPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'survey/:surveyId',
    loadChildren: () => import('./pages/extensions/surveys/survey/survey.module').then(m => m.SurveyPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'group/:groupId',
    loadChildren: () => import('./pages/extensions/groups/group/group.module').then(m => m.GroupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'user-group',
    loadChildren: () => import('./pages/core/user-groups/user-group/user-group.module').then(m => m.UserGroupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'user-groups',
    loadChildren: () => import('./pages/core/user-groups/user-groups/user-groups.module').then(m => m.UserGroupsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'monetization-extension',
    loadChildren: () => import('./pages/extensions/monetization/monetization-extension/monetization-extension.module').then(m => m.MonetizationExtensionPageModule)
  },
  {
    path: 'events-admin',
    loadChildren: () => import('./pages/extensions/events/events-admin/events-admin.module').then(m => m.EventsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'event/:eventId',
    loadChildren: () => import('./pages/extensions/events/event/event.module').then(m => m.EventPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-website-component',
    loadChildren: () => import('./pages/extensions/websites/edit-website-component/edit-website-component.module').then(m => m.EditWebsiteComponentPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'edit-website-menu',
    loadChildren: () => import('./pages/extensions/websites/edit-website-menu/edit-website-menu.module').then(m => m.EditWebsiteMenuPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/extensions/pages/pages/pages.module').then(m => m.PagesPageModule)
  },
  {
    path: 'chat/create',
    loadChildren: () => import('./pages/core/chat/create-chat-modal/create-chat-modal.module').then(m => m.CreateChatModalPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/core/account/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reportings-admin',
    loadChildren: () => import('./pages/extensions/reportings/reportings-admin/reportings-admin.module').then(m => m.ReportingsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'reporting',
    loadChildren: () => import('./pages/extensions/reportings/reporting/reporting.module').then(m => m.ReportingPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'calendars',
    loadChildren: () => import('./pages/extensions/calendar/calendars/calendars.module').then(m => m.CalendarsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/extensions/calendar/calendar/calendar.module').then(m => m.CalendarPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'crud/item/:serviceName/:tableName/create',
    loadChildren: () => import('./pages/core/crud/crud-item/crud-item.module').then(m => m.CrudItemPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'crud/item/:serviceName/:tableName/:itemId',
    loadChildren: () => import('./pages/core/crud/crud-item/crud-item.module').then(m => m.CrudItemPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'crud/list',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'crud/list/:serviceName',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'crud/list/:serviceName/:tableName',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'db/tables',
    loadChildren: () => import('./pages/core/db/db-tables/db-tables.module').then(m => m.DbTablesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'db/table/:tableName',
    loadChildren: () => import('./pages/core/db/db-table/db-table.module').then(m => m.DbTablePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'db/table/:tableName/:itemId',
    loadChildren: () => import('./pages/core/db/db-table-item/db-table-item.module').then(m => m.DbTableItemPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'announcements/death',
    loadChildren: () => import('./pages/extensions/announcements/death/death-announcements/death-announcements.module').then(m => m.DeathAnnouncementsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'announcement/death/:itemId',
    loadChildren: () => import('./pages/extensions/announcements/death/death-announcement/death-announcement.module').then(m => m.DeathAnnouncementPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'announcements/real-estates',
    loadChildren: () => import('./pages/extensions/announcements/real-estates/real-estates/real-estates.module').then(m => m.RealEstatesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'announcements/real-estates/:itemId',
    loadChildren: () => import('./pages/extensions/announcements/real-estates/real-estate/real-estate.module').then(m => m.RealEstatePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'coupons-admin',
    loadChildren: () => import('./pages/extensions/coupons/coupons-admin/coupons-admin.module').then(m => m.CouponsAdminPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'files/explorer',
    loadChildren: () => import('./pages/core/files/explorer/files-explorer.module').then(m => m.FilesExplorerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'getgenius/dani',
    loadChildren: () => import('./pages/getgenius/dani/dani.module').then(m => m.DaniPageModule)
  },
  {
    path: 'getgenius/dani/alert/learn',
    loadChildren: () => import('./pages/getgenius/dani-alert-learn/dani-alert-learn.module').then(m => m.DaniAlertLearnPageModule)
  },
  {
    path: 'dani/chat',
    loadChildren: () => import('./pages/getgenius/dani/dani.module').then(m => m.DaniPageModule)
  },
  {
    path: 'getgenius/wallet',
    loadChildren: () => import('./pages/getgenius/wallet/getgenius-wallet.module').then(m => m.GetgeniusWalletPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'jobs',
    loadChildren: () => import('./pages/extensions/jobs/jobs/jobs.module').then(m => m.JobsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'job/:jobId',
    loadChildren: () => import('./pages/extensions/jobs/job/job.module').then(m => m.JobPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'job-titles',
    loadChildren: () => import('./pages/extensions/jobs/job-titles/job-titles.module').then(m => m.JobTitlesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'job-title/:jobTitleId',
    loadChildren: () => import('./pages/extensions/jobs/job-title/job-title.module').then(m => m.JobTitlePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'quizzes',
    loadChildren: () => import('./pages/extensions/quizzes/quizzes/quizzes.module').then(m => m.QuizzesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'quiz/:quizId',
    loadChildren: () => import('./pages/extensions/quizzes/quiz/quiz.module').then(m => m.QuizPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'company/:companyId',
    loadChildren: () => import('./pages/extensions/companies/company/company.module').then(m => m.CompanyPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/extensions/companies/companies/companies.module').then(m => m.CompaniesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations',
    loadChildren: () => import('./pages/integrations/integrations/integrations.module').then(m => m.IntegrationsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/comments',
    loadChildren: () => import('./pages/integrations/integration/generic/comments/comments.module').then(m => m.CommentsPageModule),
    canActivate: [AuthService],
  },

  /*
  Generic crud handler for log:
  {
    path: 'integrations/log',
    loadChildren: () => import('./pages/core/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },

  New log-entries handler:
  */
  {
    path: 'integrations/log',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/log/:logType',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integration/settings/:integrationName',
    loadChildren: () => import('./pages/integrations/integration/integration-settings/integration-settings.module').then(m => m.IntegrationSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/custom/elevenlabs/tts/create',
    loadChildren: () => import('./pages/integrations/integration/custom/elevenlabs/create-tts/elevenlabs-create-tts.module').then(m => m.ElevenlabsCreateTtsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/custom/heygen/video/edit',
    loadChildren: () => import('./pages/integrations/integration/custom/heygen/edit-video/heygen-edit-video.module').then(m => m.HeygenEditVideoPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/blacklist',
    loadChildren: () => import('./pages/extensions/mail/mail-crawler-blacklist/mail-crawler-blacklist.module').then(m => m.MailCrawlerBlacklistPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/crawler',
    loadChildren: () => import('./pages/extensions/mail/mail-crawler/mail-crawler.module').then(m => m.MailCrawlerPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/crawlers',
    loadChildren: () => import('./pages/extensions/mail/mail-crawlers/mail-crawlers.module').then(m => m.MailCrawlersPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/inbox',
    loadChildren: () => import('./pages/extensions/mail/mail-inbox/mail-inbox.module').then(m => m.MailInboxPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/rule',
    loadChildren: () => import('./pages/extensions/mail/mail-rule/mail-rule.module').then(m => m.MailRulePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'mail/settings',
    loadChildren: () => import('./pages/extensions/mail/mail-crawler-settings/mail-crawler-settings.module').then(m => m.MailCrawlerSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/custom/wordpress',
    loadChildren: () => import('./pages/integrations/integration/custom/wordpress/wordpress.module').then(m => m.WordpressPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/custom/meta/groups',
    loadChildren: () => import('./pages/integrations/integration/custom/meta/meta-groups/meta-groups.module').then(m => m.MetaGroupsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/custom/meta/group',
    loadChildren: () => import('./pages/integrations/integration/custom/meta/meta-group/meta-group.module').then(m => m.MetaGroupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/google/youtube', // @deprecated
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule),
    canActivate: [AuthService],
  },
  /*
  {
    path: 'integrations/integration/telegram/bots', // @deprecated
    loadChildren: () => import('./pages/integrations/integration/generic/connections/generic-connections.module').then(m => m.GenericConnectionsPageModule)
  },
  */
  {
    path: 'integrations/integration/:integrationName',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/:integrationName/comments',
    loadChildren: () => import('./pages/integrations/integration/generic/comments/comments.module').then(m => m.CommentsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/:integrationName/connection',
    loadChildren: () => import('./pages/integrations/integration/generic/connection/generic-connection.module').then(m => m.GenericConnectionPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/:integrationName/connections',
    loadChildren: () => import('./pages/integrations/integration/generic/connections/generic-connections.module').then(m => m.GenericConnectionsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'integrations/integration/:integrationName/log',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'teams',
    loadChildren: () => import('./pages/core/teams/teams/teams.module').then(m => m.TeamsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'team/:teamId',
    loadChildren: () => import('./pages/core/teams/team/team.module').then(m => m.TeamPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'audio/library',
    loadChildren: () => import('./pages/core/media/audio/library/audio-library.module').then(m => m.AudioLibraryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/create',
    loadChildren: () => import('./pages/core/media/media/create-media/create-media.module').then(m => m.CreateMediaPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/folder/settings',
    loadChildren: () => import('./pages/core/media/media/media-folder-settings/media-folder-settings.module').then(m => m.MediaFolderSettingsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/library',
    loadChildren: () => import('./pages/core/media/media/library/media-library.module').then(m => m.MediaLibraryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/library/folder/:folderId',
    loadChildren: () => import('./pages/core/media/media/library/media-library.module').then(m => m.MediaLibraryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/queue',
    loadChildren: () => import('./pages/core/media/media/media-queue/media-queue.module').then(m => m.MediaQueuePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/store/templates',
    loadChildren: () => import('./pages/core/media/templates/template-store/template-store.module').then(m => m.TemplateStorePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/templates',
    loadChildren: () => import('./pages/core/media/templates/templates/media-templates.module').then(m => m.MediaTemplatesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/templates/editor/create',
    loadChildren: () => import('./pages/core/media/templates/template-editor/template-editor.module').then(m => m.TemplateEditorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/templates/editor/:uid',
    loadChildren: () => import('./pages/core/media/templates/template-editor/template-editor.module').then(m => m.TemplateEditorPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/templates/preview/:templateId',
    loadChildren: () => import('./pages/core/media/templates/template-preview/template-preview.module').then(m => m.TemplatePreviewPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/queue/preview/:queueItemId',
    loadChildren: () => import('./pages/core/media/templates/template-preview/template-preview.module').then(m => m.TemplatePreviewPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'media/:mediaId',
    loadChildren: () => import('./pages/core/media/media/view-media/view-media.module').then(m => m.ViewMediaPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'objects/create',
    loadChildren: () => import('./pages/core/objects/create-object/create-object.module').then(m => m.CreateObjectPageModule),
    canActivate: [AuthService],
  },
  // create object type in modal:
  {
    path: 'objects/types/create',
    loadChildren: () => import('./pages/core/objects/create-object-type/create-object-type.module').then(m => m.CreateObjectTypePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'objects/types',
    loadChildren: () => import('./pages/core/objects/object-types-list/object-types-list.module').then(m => m.ObjectTypesListPageModule),
    canActivate: [AuthService],
  },
  // create object type directly in UI: (using "new" as typeId)
  {
    path: 'objects/types/:typeId',
    loadChildren: () => import('./pages/core/objects/object-types-list/object-types-list.module').then(m => m.ObjectTypesListPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'sharing/queue',
    loadChildren: () => import('./pages/extensions/sharing/sharing-queue/sharing-queue.module').then(m => m.SharingQueuePageModule),
    canActivate: [AuthService],
  },
  {
    path: 'sharing/log',
    loadChildren: () => import('./pages/extensions/sharing/sharing-log/sharing-log.module').then(m => m.SharingLogPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'project/:projectId',
    loadChildren: () => import('./pages/core/projects/project/project.module').then(m => m.ProjectPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/core/projects/projects/projects.module').then(m => m.ProjectsPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/getgenius/getgenius-setup/getgenius-setup.module').then(m => m.GetgeniusSetupPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'countries',
    loadChildren: () => import('./pages/core/regions/countries/countries.module').then(m => m.CountriesPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'country/:countryId',
    loadChildren: () => import('./pages/core/regions/country/country.module').then(m => m.CountryPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'city/:cityId',
    loadChildren: () => import('./pages/core/regions/city/city.module').then(m => m.CityPageModule),
    canActivate: [AuthService],
  },
  {
    path: 'cities',
    loadChildren: () => import('./pages/core/regions/cities/cities.module').then(m => m.CitiesPageModule),
    canActivate: [AuthService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }