import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class SourcesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private loading: LoadingController,
    private userService: UserService,
  ) {

  }

  createSource(source: source) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        source: source,
      },
      ["sources", "create"]
    );
  }

  deleteSource(sourceId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["sources", sourceId, "delete"]
    );
  }

  getGlobalSources(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      config.user = config.user || this.userService.getApiCredentials();
      config.userId = config.userId || this.userService.getUid();

      this.AppCMS.loadPluginData("pipeline", config, ["sources", "global"], {}, blForceRefresh)
        .then((sources: source[]) => {
          resolve(sources);
        })
        .catch(reject);
    });
  }

  getSources(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", config, ["sources"], {}, blForceRefresh)
        .then((sources: source[]) => {

          if (sources && sources.length) {
            sources.forEach((source: source) => {

              // @todo fix this duplicate key
              source.name = source.name || source.title;
              source.title = source.title || source.name;

              source.system = source.system || 'blog';
            });
          }
          resolve(sources as source[]);
        })
        .catch(reject);
    })
  }

  getSourceByUid(sourceId: number, blForceRefresh: boolean = false, config: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', config, ['sources', sourceId], {}, blForceRefresh);
  }

  getSourceSettings(sourceId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["sources", sourceId, "settings"]
    );
  }

  groupSystemsToSources(sources: source[]) {
    if (sources && sources.length) {
      let _sources = {};

      sources.forEach((source: source) => {
        _sources[source.uid] = _sources[source.uid] || source;
        _sources[source.uid].systems = _sources[source.uid].systems || [];
        if (_sources[source.uid].systems.indexOf(source.system) === -1) {
          _sources[source.uid].systems.push(source.system);
        }
      });

      sources = (Object.values(_sources) as source[]);
    }

    return sources;
  }

  index(source: source) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", {}, [
        "sources",
        source.uid,
        "index",
      ])
        .then(resolve)
        .catch(reject);
    });
  }

  pick(options: any = null) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create();
      loading.present();

      const sources: source[] = (!!options.data ? options.data : (await this.getSources() as source[]));
      loading.dismiss();

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: sources,
        labelKey: 'name',
        service: this,
        subLabelKey: 'url',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  updateSource(source: source) {
    console.log('updateSource', source);

    return this.AppCMS.loadPluginData('pipeline', {
      source: source,
      user: this.userService.getUid()
    }, ['sources', source.uid, 'update']);
  }

  updateSourcePermissions(permissions: any, sourceId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      permissions: permissions,
      source: sourceId,
      user: this.userService.getUid()
    }, ['sources', sourceId, 'permissions', 'update']);
  }

}
