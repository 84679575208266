<ion-header class="ion-no-border">

  <!-- Top bar -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button *ngIf="view.segment === 'ai'" (click)="generate()"
        [disabled]="!search || !search.query || !search.query.length" shape="round"
        [color]="view.canSubmit ? 'dark' : 'primary'" [fill]="view.canSubmit ? 'clear' : 'solid'">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="submit()" [disabled]="!view.canSubmit" color="primary" fill="solid" shape="round">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="container ion-no-border second-toolbar">
    <ion-segment scrollable [(ngModel)]="view.segment" (ionChange)="segmentChanged()">

      <ion-segment-button value="upload" *ngIf="!!view.services.upload">
        <ion-icon [name]="'cloud-upload-outline'"></ion-icon>
        <ion-label [innerHTML]="'upload'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="media_library" *ngIf="!!view.services.media_library">
        <ion-icon [name]="'images-outline'"></ion-icon>
        <ion-label [innerHTML]="'media_library'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="ai" *ngIf="!!view.services.ai && !!appConfig.useMediaCreator">
        <ion-icon [name]="'sparkles-outline'"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="database" *ngIf="!!view.services.database">
        <ion-icon [name]="'search-outline'"></ion-icon>
        <ion-label [innerHTML]="'stock_photos'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <!-- Database toolbar -->
  <ion-toolbar class="ion-no-border container second-toolbar" *ngIf="view.segment == 'database'">
    <ion-grid>
      <ion-row>

        <ion-col size="10">
          <ion-searchbar autocorrect="on" [(ngModel)]="search.query" #searchInput id="searchInput"
            [placeholder]="'search_something'|translate" (ionInput)="runSearch($event)"></ion-searchbar>
        </ion-col>

        <ion-col size="2" *ngIf="(view.segment == 'database') && (!!view.filters && !!view.mimeTypes)">
          <ion-item>
            <ion-icon
              [name]="view.mimeType === 'image' ? 'image-outline' : (view.mimeType === 'video' ? 'film-outline' : 'images-outline')"
              slot="start"></ion-icon>
            <ion-select [(ngModel)]="view.filters.mimeType" [label]="'type'|translate" labelPlacement="stacked"
              [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
              <ion-select-option *ngFor="let type of view.mimeTypes" [innerHTML]="type|translate"
                [value]="type"></ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <!-- Search results selection bar -->
  <pipeline-selection-toolbar
    *ngIf="(!!search.results && !!search.results.length) && (!view.files || !view.files.length) && (view.segment == 'ai' || view.segment == 'database')"
    [(items)]="search.results" [options]="view.selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

  <!-- Files selection bar -->
  <pipeline-selection-toolbar
    *ngIf="(!!view.files && !!view.files.length) && (view.segment == 'ai' || view.segment == 'database')"
    [(items)]="view.files" [options]="view.selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" *ngIf="view.segment === 'media_library'" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Main container -->
  <div class="container">

    <!-- Upload grid -->
    <ion-grid [hidden]="view.segment != 'upload'">

      <ion-row>

        <!-- Left config col -->
        <ion-col [size]="view.hasThumbnailImg ? 8 : 12">

          <!-- Type: URL -->
          <ion-card [hidden]="!view.allowUrl">
            <ion-item lines="none">
              <ion-label>
                <h3 [innerHTML]="'upload_type_url'|translate"></h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-icon name="link-outline" slot="start"></ion-icon>
              <ion-input type="text" [(ngModel)]="view.url" [placeholder]="'url'|translate"
                (ionInput)="urlChanged()"></ion-input>
            </ion-item>

          </ion-card>

          <!-- Type: YouTube embed -->
          <ion-card [hidden]="!view.allowYoutubeEmbed">
            <ion-item lines="none">
              <ion-label>
                <h3 [innerHTML]="'upload_type_youtube_embed'|translate"></h3>
              </ion-label>
            </ion-item>

            <ion-item lines="none">
              <ion-textarea rows="5" [(ngModel)]="view.youtube_embed_code"
                [placeholder]="'youtube_embed_code'|translate" (ionInput)="youtubeEmbedCodeChanged()"></ion-textarea>
            </ion-item>
          </ion-card>

          <!-- Type: Upload -->
          <ion-card [hidden]="!view.allowFileUpload">
            <ion-item lines="none">
              <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
              <ion-label>
                <h3 [innerHTML]="'upload_type_file'|translate"></h3>
              </ion-label>
            </ion-item>
            <input type="file" class="web-uploader-file" [multiple]="!!view.multiple" />
          </ion-card>

        </ion-col>

        <!-- Right preview col -->
        <ion-col [hidden]="!view.hasThumbnailImg" size="4">
          <ion-img [src]="view.thumbnailImg" class="thumbnail-preview background" (ionError)="thumbnailLoadingFailed()"
            (ionImgDidLoad)="ionImgDidLoad($event)"></ion-img>
          <img [src]="view.url" class="thumbnail-preview" />
        </ion-col>

      </ion-row>

      <!-- Files list -->
      <ion-row *ngIf="!!view.files">
        <ion-col>

          <ion-card>

            <ion-radio-group [(ngModel)]="view.url" (ionChange)="urlChanged()">

              <!-- Iterate files -->
              <ion-item *ngFor="let file of view.files">

                <ion-thumbnail *ngIf="!!file.src && file.type !== 'video/mp4'" slot="start">
                  <ion-img [src]="file.thumbnail || file.src" (ionError)="fileThumbnailLoadingFailed(file)"></ion-img>
                </ion-thumbnail>

                <video *ngIf="!!file.src && file.type === 'video/mp4'" [poster]="fallbackImg" [src]="file.src"
                  playsinline webkit-playsinline muted="muted" loop autoplay></video>

                <ion-spinner slot="end" [hidden]="file.response ||
                  !!file.error"></ion-spinner>

                <ion-icon name="warning-outline" color="danger" slot="end" [hidden]="!file.error"></ion-icon>

                <!-- Mode: Multi -->
                <ion-checkbox justify="start" labelPlacement="end" class="ion-text-wrap" *ngIf="!!view.multiple"
                  [disabled]="!file.response" [(ngModel)]="file.checked" [hidden]="!!file.error" [value]="file.url"
                  (ionChange)="onFileMultiItemClick(file)">
                  <ion-label class="ion-text-wrap">
                    <h2 [innerHTML]="file.name"></h2>
                    <!--<p [innerHTML]="file.url || file.src"></p>-->
                  </ion-label>
                </ion-checkbox>

                <!-- Mode: Single -->
                <ion-radio justify="start" labelPlacement="end" class="ion-text-wrap" *ngIf="!view.multiple"
                  [disabled]="!file.response" [hidden]="!!file.error" [value]="file.url">
                  <ion-label class="ion-text-wrap">
                    <h2 [innerHTML]="file.name"></h2>
                    <!--<p [innerHTML]="file.url || file.src"></p>-->
                  </ion-label>
                </ion-radio>

              </ion-item>

            </ion-radio-group>
          </ion-card>

        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- media generator tool -->
    <pipeline-media-creator [(cards)]="cards" *ngIf="!!appConfig.useMediaCreator" [hidden]="view.segment != 'ai'"
      [(search)]="search" [(view)]="assetsView" (onSelectionChanged)="onSelectionChanged($event)"
      (onViewChanged)="onViewChanged($event)"></pipeline-media-creator>

    <!-- media library -->
    <pipeline-media-library [hidden]="view.segment != 'media_library'"
      [(view)]="view.libraryView"></pipeline-media-library>

    <!-- stock photos -->
    <ion-grid [hidden]="view.segment != 'database'">

      <ion-spinner [hidden]="!view.loading"></ion-spinner>

      <ion-row *ngIf="!!view.mediaList || !!view.mediaFolders" [hidden]="view.loading">
        <ion-col *ngFor="let result of search.results; let i = index;" [size]="view.colSize"
          [hidden]="view.segment === 'media_library'">
          <ion-card (click)="chooseMediaFromList(result, i, search.results)" class="mediaItem"
            [class.active]="result.checked">

            <ion-thumbnail>
              <ion-img [src]="(result.thumbnail || result.photo) || fallbackImg"
                (ionError)="thumbnailLoadingFailed(result)"></ion-img>
            </ion-thumbnail>

            <ion-icon name="checkmark-outline" class="video" color="white" [hidden]="!result.checked"></ion-icon>
            <ion-icon name="film-outline" class="video" color="white"
              [hidden]="!!result.checked || result.post_mime_type != 'video'"></ion-icon>

          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer *ngIf="view.segment === 'ai'" class="ion-no-border" [hidden]="!view.isDesktop && (view.phase !== 'input')">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker-->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>